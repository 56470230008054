import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage } from '@cores/utils/functions';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { maxDate, maxDateToday, minDate, minDateToday } from '@cores/utils/custom-validators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lp-input-date',
  templateUrl: './lp-input-date.component.html',
  styleUrls: ['./lp-input-date.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputDateComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputDateComponent),
      multi: true,
    },
  ],
})
export class LPInputDateComponent implements Validator, ControlValueAccessor {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() dateFormat: string = 'dd/mm/yy';
  @Input() showLabel: boolean = true;
  @Input() view: 'date' | 'month' = 'date';
  @Input() selectionMode: string = 'single'; // single, multiple, range
  @Input() required?: boolean | string;
  @Input() readonly: boolean = false;
  @Input() maxDateLabel: string = '';
  @Input() minDateLabel: string = '';
  @Input() disabled: boolean = true;
  @Input() border: boolean = true;
  @Input() timeOnly: boolean = false;
  @Input() disabledDays: number[] = [];
  @Input() disabledDates: Date[] = [];

  @ViewChild('calendar') calendarInput: any;
  absControl!: AbstractControl;
  control = new UntypedFormControl(null);
  initComp: boolean = false;

  constructor(
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        const val = value instanceof Date ? moment(value).format('YYYY-MM-DD').toString() : value;
        this.onChange(val);
      }
    });
  }

  _maxDateToday = false;

  get maxDateToday() {
    return this._maxDateToday;
  }

  @Input() set maxDateToday(value: boolean) {
    this._maxDateToday = value;
    if (this._maxDateToday) {
      if (moment(this.maxDate).isValid() && moment(new Date()).isAfter(moment(this.maxDate), 'day')) {
        return;
      } else {
        this._maxDate = new Date();
      }
    }
  }

  _minDateToday = false;

  get minDateToday() {
    return this._minDateToday;
  }

  @Input() set minDateToday(value: boolean) {
    this._minDateToday = value;
    if (this._minDateToday) {
      if (moment(this.minDate).isValid() && moment(new Date()).isBefore(moment(this.minDate), 'day')) {
        return;
      } else {
        this._minDate = new Date();
      }
    } else {
      this._minDate = undefined;
    }
  }

  _maxDate: any;

  get maxDate(): Date {
    return this._maxDate;
  }

  @Input() set maxDate(value: string | Date | undefined) {
    if (value) {
      this._maxDate = moment(value).subtract(1, 'd').toDate();
    } else {
      this._maxDate = undefined;
    }
    if (this.maxDateToday) {
      if (moment(this.maxDate).isValid()) {
        if (moment(this.maxDate).isBefore(moment(new Date()))) {
          return;
        }
      }
      this._maxDate = new Date();
    }
  }

  _minDate: any;

  get minDate(): Date {
    return this._minDate;
  }

  @Input() set minDate(value: string | Date | undefined) {
    if (value) {
      this._minDate = moment(value).add(1, 'd').toDate();
    } else {
      this._minDate = undefined;
    }
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  onBlur(event: any) {
    if (!this.control.value) {
      return;
    }
    if (!(this.control.value instanceof Date) && moment(this.control.value.trim(), 'DDMMYYYY', true).isValid()) {
      let trimmedValue = this.control.value.trim();
      const valueToDisplay = moment(trimmedValue, 'DDMMYYYY').format('DD/MM/YYYY').toString();
      const val = moment(trimmedValue, 'DD/MM/YYYY').format('YYYY-MM-DD').toString();
      this.control.setValue(valueToDisplay);
      this.onChange(val);
    } else if (!(this.control.value instanceof Date)) {
      let trimmedValue = event.target.value.trim();
      if (trimmedValue !== '') {
        if (moment(trimmedValue, 'DD/MM/YYYY', true).isValid()) {
          this.control.setValue(trimmedValue);
          const val = moment(trimmedValue, 'DD/MM/YYYY').format('YYYY-MM-DD').toString();
          this.onChange(val);
        } else {
          this.control.setValue(trimmedValue);
          this.onChange(trimmedValue);
        }
      }
    }
  }

  onInput(event: any) {
    if (moment(event.target.value, 'DD/MM/YYYY', true).isValid()) {
      const val = moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD').toString();
      this.onChange(val);
      return;
    }
    this.onChange(event.target.value);
  }

  onChange = (_value: any) => {};

  onTouched = () => {};

  // trimInput() {
  //   if (this.rawValue) {
  //     this.rawValue = this.rawValue.trim();
  //     this.control.setValue(this.rawValue);
  //   }
  // }

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  onPickerShow() {
    this.calendarInput.isMonthDisabled = (month: number) => {
      for (
        let day = 1;
        day < this.calendarInput.getDaysCountInMonth(month, this.calendarInput.currentYear) + 1;
        day++
      ) {
        if (this.calendarInput.isSelectable(day, month, this.calendarInput.currentYear, false)) {
          return false;
        }
      }
      return true;
    };
  }

  writeValue(value: string): void {
    if (value?.match(/(\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)) {
      let tmpDate: Date = new Date(value);
      this.control.setValue(tmpDate, { emitEvent: false });
    } else {
      this.control.setValue(value, { emitEvent: false });
    }
    this.initComp = true;
    if (this.absControl) {
      this.absControl.markAsPristine();
    }
  }

  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  // Đừng ai xoá chỗ này nha
  //   else if (this.minDate && this.maxDate) {
  //   return dateValue.isBetween(moment(this.minDate), moment(this.maxDate), 'day', '[]')
  // ? null
  //     : { minDateAndMaxDate: { minDateLabel: this.minDateLabel, maxDateLabel: this.maxDateLabel } };
  // }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    const val: any = control.value;
    let dateValue = val;
    if (
      !moment(dateValue, 'DD/MM/YYYY', true).isValid() &&
      !moment(dateValue, 'YYYY-MM-DD', true).isValid() &&
      !(moment(dateValue).isValid() && typeof dateValue !== 'string')
    ) {
      if (isEmpty(dateValue)) {
        return null;
      } else {
        return { datePattern: { requiredPattern: 'dd/mm/yyyy', actualValue: val } };
      }
    }

    let errors: any[] = [];

    dateValue = moment(dateValue, 'DD/MM/YYYY', true).isValid()
      ? moment(dateValue, 'DD/MM/YYYY')
      : moment(dateValue, 'YYYY-MM-DD');
    if (this.minDateToday) {
      errors.push(minDateToday(dateValue));
    }
    if (this.maxDateToday) {
      errors.push(maxDateToday(dateValue));
    }

    if (this.minDate) {
      errors.push(minDate(dateValue, this.minDate, this.translate.instant(this.minDateLabel || 'EMPTY')));
    }

    if (this.maxDate) {
      errors.push(maxDate(dateValue, this.maxDate, this.translate.instant(this.maxDateLabel || 'EMPTY')));
    }
    return errors.filter((e: any) => e)[0] || null;
  }
}
