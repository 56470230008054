<app-loading *ngIf="isLoading"></app-loading>
<div class="p-dialog-header">
  <span class="p-dialog-title">{{ 'DIALOG.HEADER.EMPLOYEE-SEARCH' | translate }}</span>
  <div class="p-dialog-header-icons">
    <button (click)="cancel()" class="p-dialog-header-icon p-dialog-header-maximize p-link" type="button">
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
<div class="grid p-fluid p-dialog-body">
  <div class="col-12">
    <div class="grid">
      <div [formGroup]="params" class="col-4 mb-2">
        <lp-input-text
          (keyup.enter)="search(true)"
          [maxlength]="50"
          formControlName="employeeCode"
          label="INPUT-FIELD.EMPLOYEE-CODE.LABEL"></lp-input-text>
      </div>
      <div [formGroup]="params" class="col-4 mb-2">
        <lp-input-text
          (keyup.enter)="search(true)"
          [maxlength]="50"
          formControlName="fullName"
          label="INPUT-FIELD.FULLNAME.LABEL">
        </lp-input-text>
      </div>
      <div [formGroup]="params" class="col-4 mb-2">
        <lp-input-select
          [filter]="true"
          [options]="getValue(droplistData, 'title')"
          formControlName="titleCode"
          label="INPUT-FIELD.TITLE.LABEL"></lp-input-select>
      </div>
      <div [formGroup]="params" class="col-4 mb-2">
        <lp-input-select
          [filter]="true"
          [options]="getValue(droplistData, 'rank')"
          formControlName="rankCode"
          label="INPUT-FIELD.RANK.LABEL"></lp-input-select>
      </div>
      <div [formGroup]="params" class="col-4 mb-2">
        <lp-input-select
          [filter]="true"
          [options]="getValue(droplistData, 'position')"
          formControlName="positionCode"
          label="INPUT-FIELD.POSITION.LABEL"></lp-input-select>
      </div>
      <div class="col-12">
        <div class="flex align-items-center">
          <p-button (click)="search(true)" label="{{ 'BUTTON.SEARCH' | translate | uppercase }}"></p-button>
          <em (click)="reload()" class="lp-reload ml-3" pTooltip="{{ ('BUTTON.RELOAD-DATA' | translate) }}"></em>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 h-full">
    <div class="table-section">
      <p-table
        #table
        [(selection)]="selectedEmployee"
        [columns]="cols"
        [loading]="loadingService.loading"
        [rowHover]="true"
        [scrollable]="true"
        [showLoader]="false"
        [value]="dataTable.content"
        responsiveLayout="scroll"
        scrollDirection="both"
        scrollHeight="flex"
        selectionMode="multiple">
        <!--      TABLE HEADER-->
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" style="width: 40px"></th>
            <th scope="col" style="width: 70px; text-align: center">STT</th>
            <th *ngFor="let col of cols"
                [ngStyle]="{
                'min-width': col.width
              }"
                scope="col">
              {{ col.headerName }}
            </th>
          </tr>
        </ng-template>
        <!--      TABLE BODY-->
        <ng-template let-employee let-i="rowIndex" pTemplate="body">
          <tr [pSelectableRowIndex]="i" [pSelectableRow]="employee">
            <td class="action-col" style="width: 40px; text-align: center">
              <p-tableCheckbox
                [index]="i"
                [pSelectableRowIndex]="i"
                [pSelectableRow]="employee"
                [value]="employee"></p-tableCheckbox>
            </td>
            <td style="text-align: center; width: 70px">{{ dataTable.currentPage * dataTable.size + i + 1 }}</td>
            <td
              *ngFor="let col of cols"
              [ngStyle]="{
                'min-width': col.width
              }">
              {{ col['type'] === 'date' ? (employee[col.fieldName] | date: 'dd/MM/yyyy') : employee[col.fieldName] }}
            </td>
          </tr>
        </ng-template>
        <ng-template let-columns pTemplate="emptymessage">
          <div class="table-empty-img">
            <img alt="No record found img" src="./assets/images/no-records.png"/>
            <span>{{ 'COMMON.NO-RECORD' | translate | uppercase }}</span>
          </div>

        </ng-template>
      </p-table>
    </div>
    <p-paginator
      #paginator
      (onPageChange)="pageChange($event)"
      [first]="dataTable.first"
      [rows]="dataTable.size"
      [showCurrentPageReport]="true"
      [totalRecords]="dataTable.totalElements"
      currentPageReportTemplate="{{ 'COMMON.TOTAL' | translate }} {{ dataTable.totalElements | number }} {{
        ('COMMON.RECORD' | translate).toLowerCase()
      }} / {{ dataTable.totalPages | number }} {{ ('COMMON.PAGES' | translate).toLowerCase() }}"
      dropdownAppendTo="body"></p-paginator>
  </div>
</div>

<div class="p-dialog-footer">
  <button
    (click)="select()"
    *ngIf="screenType !== 2"
    icon="pi pi-check"
    label="{{ 'BUTTON.SELECT' | translate }}"
    pButton></button>

  <button
    (click)="cancel()"
    class="lp-dialog-cancel"
    icon="pi pi-times"
    label="{{ 'BUTTON.CLOSE' | translate }}"
    pButton></button>
</div>
