import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { BaseData } from '../abstract/base-data';
import { DataTable } from '../models/data-table.model';
import { mapDataTable, removeParamNullOrUndefined } from '../utils/functions';
import { FileModel } from '@models/file.model';
import { environment } from '@env';

@Injectable()
export class BaseService implements BaseData {
  baseUrl!: string;
  state = new Map<string, any>();

  constructor(public http: HttpClient, @Inject(String) public baseURL: string) {
    this.baseUrl = baseURL;
  }

  getState(): Observable<any> {
    return of(this.state);
  }

  fetch(): Observable<any> {
    return this.http.get(`${this.baseUrl}`);
  }

  search(params?: any, isPost?: boolean): Observable<DataTable> {
    const newParam: any = removeParamNullOrUndefined(params);
    if (isPost) {
      return this.http.post<DataTable>(`${this.baseUrl}`, { params: newParam });
    }
    return this.http
      .get<DataTable>(`${this.baseUrl}`, {
        params: { ...newParam },
      })
      .pipe(map(data => mapDataTable(data, params)));
  }

  findByCode(code: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${code}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}`, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}`, data);
  }

  updateById(id: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, data);
  }

  updateFromList(code: string, data: {}): Observable<any> {
    return this.http.put(`${this.baseUrl}/update/${code}`, data);
  }

  delete(id: string | number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  downloadFile(file: FileModel): Observable<any> {
    return this.http
      .get(`${environment.endpoint_url}/e-file-cabinet/download/${file.ecmId}`, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map(res => {
          saveAs(
            new Blob([res], {
              type: 'application/octet-stream',
            }),
            file.fileName
          );
          return true;
        })
      );
  }

  exportExcel(fileName: string, params?: any): Observable<boolean> {
    return this.http.get(`${this.baseUrl}/export`, { params: { ...params }, responseType: 'arraybuffer' }).pipe(
      map(res => {
        saveAs(
          new Blob([res], {
            type: 'application/octet-stream',
          }),
          fileName
        );
        return true;
      })
    );
  }
}
