import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { BaseService } from '@cores/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class PersonalizeApi extends BaseService {
  constructor(http: HttpClient) {
    super(http, `${environment.endpoint_url}/personalized`);
  }

  override update(body: any) {
    return this.http.put(`${this.baseURL}/update`, body);
  }
}
