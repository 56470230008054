export const APP_LOADING = 'APP_LOADING';
export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const REFRESH_BREACRUMB = 'REFRESH_BREACRUMB';

export const MONTH_DROPLIST = [
  { name: 'Tháng 1', code: '1' },
  { name: 'Tháng 2', code: '2' },
  { name: 'Tháng 3', code: '3' },
  { name: 'Tháng 4', code: '4' },
  { name: 'Tháng 5', code: '5' },
  { name: 'Tháng 6', code: '6' },
  { name: 'Tháng 7', code: '7' },
  { name: 'Tháng 8', code: '8' },
  { name: 'Tháng 9', code: '9' },
  { name: 'Tháng 10', code: '10' },
  { name: 'Tháng 11', code: '11' },
  { name: 'Tháng 12', code: '12' },
];

export const MenuIcon: any = {
  DASHBOARD_MODULE: 'lp-dashboard',
  EMPLOYEE_MODULE: 'lp-employees',
  ORGANIZATION_MODULE: 'lp-organization',
  EMPLOYEE_MANAGEMENT_INFO_MODULE: 'lp-employee-info',
  RECRUIT_APPLICATION_MODULE: 'lp-candidate',
  ADMIN_SYSTEM_MODULE: 'lp-admin-menu',
  PROJECT_MANAGEMENT_MODULE: 'lp-project',
  REVIEW_PARENT_MODULE: 'lp-rate',
  ON_LEAVE_MODULE: 'lp-on-leave',
  TIMEKEEPING_PARENT_MODULE: 'lp-time-keeping',
  ONBOARDING_MODULE: 'lp-onboarding',
  SALARY_MODULE: 'lp-salary', 
  COURSE_MODULE: 'lp-course' 
};

export const DATE_PATTERN =
  '/^(?:(?:31(\\/)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$/';

export const BACKEND_DATE_FORMAT = '/(\\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))/'; //2022-02-20

export const REGEX_CONSTANT: any = {
  alphabetAndNumeric: /^[a-zA-Z0-9]*$/,
  blockSpecialCharacter: /^[^~`!@#$%^&()_={}[\]:;,.<>+\/?-]*$/,
  onlyNumber: /^\d*$/,
  timeFormat: /^([0-1]?\d|2[0-3]):[0-5]\d$/,
};

//Dùng cho multilanguage
export const VALIDATORS_KEY: any = {
  required: 'VALIDATE_ERROR.REQUIRED',
  pattern: 'VALIDATE_ERROR.PATTERN',
  minlength: 'VALIDATE_ERROR.MINLENGTH',
  maxlength: 'VALIDATE_ERROR.MAXLENGTH',
  max: 'VALIDATE_ERROR.MAX',
  min: 'VALIDATE_ERROR.MIN',
  email: 'VALIDATE_ERROR.EMAIL',
  datePattern: 'VALIDATE_ERROR.DATEPATTERN',
  minDateAndMaxDate: 'VALIDATE_ERROR.MINDATE-MAXDATE',
  minDate: 'VALIDATE_ERROR.MINDATE',
  maxDate: 'VALIDATE_ERROR.MAXDATE',
  phonePattern: 'VALIDATE_ERROR.PHONEPATTERN',
  maxDateToday: 'VALIDATE_ERROR.MAXDATE-TODAY',
  minDateToday: 'VALIDATE_ERROR.MINDATE-TODAY',
  minAtTheMoment: 'VALIDATE_ERROR.MIN-ATTHEMOMENT',
  requiredLength: 'VALIDATE_ERROR.REQUIRED-LENGTH',
};

export const EMAIL_PATTERN = '[^.][a-zA-Z0-9.-_][^<>/:;,+=@]{1,}@[a-zA-Z]{2,}[.][a-zA-Z]{2,}';
export const TEXT_PATTERN =
  '^[a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$';
