export enum NotifyMessageType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

export enum SessionKey {
  UserProfile = 'KEY_USER_PROFILE',
}

export enum CategoryCode {
  MS = 'MS',
  DEPT = 'DEPT',
  TITLE = 'TITLE',
  POSN = 'POSN',
  RANK = 'RANK',
  RELA = 'RELA',
  WS = 'WS',
  IPT = 'IPT',
  HEALTH = 'HEALTH',
  LEVEL = 'LEVEL',
  TRAIN = 'TRAIN',
  CLASSIFY = 'CLASSIFY',
  GENDER = 'GENDER',
  BLOOD = 'BLOOD',
  CERTIFICATE_TYPE = 'CERTIFICATE_TYPE',
  MAJOR_CODE = 'MAJOR_CODE',
  INSTRUCT_CODE = 'INSTRUCT_CODE',
  LICENSE_TYPE = 'LICENSE_TYPE',
  NATIONALITY = 'NATIONALITY',
  RELIGION = 'RELIGION',
  PENALIZE_LEVEL = 'PENALIZE_LEVEL',
  REWARD_LEVEL = 'REWARD_LEVEL',
  REWARD_POSITION = 'REWARD_POSITION',
  PROGRAMING_LANGUAGE = 'SKILL',
  CEFR = 'CEFR',
  HOBBY = 'HOBBY',
  INDUSTRY = 'INDUSTRY',
  JOB = 'JOB',
  NOMINEE = 'NOMINEE',
  JOURNEY = 'JOURNEY',
  EXPERIENCE = 'EXPERIENCE',
  SOCIAL = 'SOCIAL',
  DEGREE_STATUS = 'DEGREE_STATUS',
  HRSOURCE = 'HRSOURCE',
  STAGES = 'STAGES',
  RECRUIT_APPLICATION_STATUS = 'RECRUIT_APPLICATION_STATUS',
  RECRUIT_REASON = 'RECRUIT_REASON',
  INTERVIEW_TYPE = 'INTERVIEW_TYPE',
  REVIEW_TYPE = 'REVIEW_TYPE',
  ASSESSMENT_OBJECT = 'ASSESSMENT_OBJECT',
  PARTNER = 'PARTNER',
  PROJECT_STATUS = 'PROJECT_STATUS',
  PROJECT_ROLE = 'PROJECT_ROLE',
  CHOICE_TYPE = 'CHOICE_TYPE',
  ON_LEAVE_STATUS = 'ON_LEAVE_STATUS',
  TIMEKEEPING_STATUS = 'TIMEKEEPING_STATUS',
  CONTRACT_TYPE = 'CONTRACT_TYPE',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
  RATE_STATUS = 'RATE_STATUS',
}

export enum ScreenType {
  Create,
  Update,
  Detail,
}

export enum RateStatus {
  WaitingRate,
  SendingApproval,
  WaitingApproval,
  Approved,
  Denied,
}

export enum MasterDataType {
  Country = 'country',
  Province = 'province',
  District = 'district',
  Ward = 'ward',
}

export enum RecruitTodoList {
  CVApproval = 'RECRUIT_TODO_LIST_01',
  Interview = 'RECRUIT_TODO_LIST_02',
  Review = 'RECRUIT_TODO_LIST_03',
  SalaryConsultation = 'RECRUIT_TODO_LIST_04',
}

export enum Action {
  Update = 'UPDATE',
  Create = 'CREATE',
  Export = 'EXPORT',
  Import = 'IMPORT',
  Delete = 'DELETE',
  View = 'VIEW',
}

export enum RecruitTodoStatus {
  Completed = 'RECRUIT_APPLICATION_TODO_STATUS_01',
  Incomplete = 'RECRUIT_APPLICATION_TODO_STATUS_02',
}

export enum CandidateStatus {
  Done = 'DONE',
  InProgress = 'IN-PROGRESS',
  Pending = 'PENDING',
  Failed = 'FAILED',
  Skipped = 'SKIPPED',
}

export enum ApplicationReviewStatus {
  Approved = 'RECRUIT_APPLICATION_REVIEW_STATUS_01',
  Declined = 'RECRUIT_APPLICATION_REVIEW_STATUS_02',
}

export enum Socials {
  Facebook = 'SOCIAL_01',
  Zalo = 'SOCIAL_02',
  Instagram = 'SOCIAL_03',
  Tiktok = 'SOCIAL_04',
  Linkedin = 'SOCIAL_05',
  Behance = 'SOCIAL_06',
  Figma = 'SOCIAL_07',
}

export enum RecruitApplicationStatus {
  New = 'RECRUIT_APPLICATION_STATUS_01',
  CVReview = 'RECRUIT_APPLICATION_STATUS_02',
  CandidateContact = 'RECRUIT_APPLICATION_STATUS_03',
  Interview = 'RECRUIT_APPLICATION_STATUS_04',
  SalaryConsultation = 'RECRUIT_APPLICATION_STATUS_05',
  HRHandling = 'RECRUIT_APPLICATION_STATUS_06',
  Onboarding = 'RECRUIT_APPLICATION_STATUS_07',
}

export enum ColumnDataType {
  Action = 'action',
  Boolean = 'boolean',
  Number = 'number',
  Text = 'text',
  Checkbox = 'checkbox',
  Date = 'date',
  Currency = 'currency',
  File = 'file',
  Array = 'array',
  Status = 'status',
  Input = 'input',
}

export enum FunctionCode {
  Dashboard = 'DASHBOARD_MODULE',
  DashboardRecruit = 'DASHBOARD_RECRUIT_MODULE',
  DashboardEmployee = 'DASHBOARD_EMPLOYEE_MODULE',
  Organization = 'ORGANIZATION_MODULE',
  OrganizationChart = 'ORGANIZATION_CHART_MODULE',
  Employee = 'EMPLOYEE_MODULE',
  EmployeeMeInfo = 'EMPLOYEE_ME_INFO_MODULE',
  EmployeeInfo = 'EMPLOYEE_INFO_MODULE',
  EmployeeList = 'EMPLOYEE_LIST_MODULE',
  EmployeeManagementInfo = 'EMPLOYEE_MANAGEMENT_INFO_MODULE',
  EmployeeManagementRelative = 'EMPLOYEE_MANAGEMENT_RELATIVE_MODULE',
  EmployeeManagementHealth = 'EMPLOYEE_MANAGEMENT_HEALTH_MODULE',
  EmployeeManagementInsurance = 'EMPLOYEE_MANAGEMENT_INSURANCE_MODULE',
  EmployeeManagementPaper = 'EMPLOYEE_MANAGEMENT_PAPER_MODULE',
  EmployeeManagementTax = 'EMPLOYEE_MANAGEMENT_TAX_MODULE',
  EmployeeManagementCertificate = 'EMPLOYEE_MANAGEMENT_CERTIFICATE_MODULE',
  EmployeeManagementReward = 'EMPLOYEE_MANAGEMENT_REWARD_MODULE',
  EmployeeManagementDiscipline = 'EMPLOYEE_MANAGEMENT_DISCIPLINE_MODULE',
  SystemConfiguration = 'SYSTEM_CONFIGURATION_MODULE',
  DataConfiguration = 'SYSTEM_DATA_CONFIGURATION_MODULE',
  Recruit = 'RECRUIT_APPLICATION_MODULE',
  RecruitTodoList = 'RECRUIT_TODO_LIST_MODULE',
  RecruitCandidateList = 'RECRUIT_CANDIDATE_LIST_MODULE',
  Admin = 'ADMIN_SYSTEM_MODULE',
  Project = 'PROJECT_MANAGEMENT_MODULE',
  ProjectList = 'PROJECT_LIST_MODULE',
  ProjectJobDeclare = 'PROJECT_JOB_DECLARE_MODULE',
  ProjectTodoList = 'PROJECT_TODO_LIST_MODULE',
  RateManagement = 'REVIEW_PARENT_MODULE',
  CriteriaList = 'CRITERIA_MANAGEMENT_MODULE',
  OnLeave = 'ON_LEAVE_MODULE',
  OnLeaveList = 'ON_LEAVE_LIST_MODULE',
  DirectList = 'DIRECT_MANAGEMENT_LIST_MODULE',
  TimeKeeping = 'TIMEKEEPING_PARENT_MODULE',
  TimeKeepingList = 'TIMEKEEPING_MODULE',
  TimeKeepingApproval = 'TIMEKEEPING_APPROVAL',
  EvaluateYourself = 'EVALUATE_YOURSELF_MODULE',
  Rate180 = 'RATE180_MODULE',
  Rate90 = 'RATE90_MODULE',
  Onboarding = 'ONBOARDING_MODULE',
  OnboardingList = 'ONBOARDING_LIST_MODULE',
  OnboardingToDoList = 'ONBOARDING_TODO_LIST_MODULE',
  RATE_APPROVE_MODULE = 'RATE_APPROVE_MODULE',
  Salary = 'SALARY_MODULE',
  Allowance = 'ALLOWANCE_MODULE',
  Course = 'COURSE_MODULE',
  CourseList = 'COURSE_LIST_MODULE',
  EvaluateManagement = 'REVIEW_MANAGEMENT_MODULE',
}

export enum OnLeaveStatus {
  Done = 1,
  Pending = 0,
  Refuse = 2,
}

export enum EmployeeStatus {
  Intern = 'WS_01',
  Trial = 'WS_02',
  Official = 'WS_03',
  Inactivity = 'WS_04',
}

export enum ProjectStatusCode {
  Code_1 = 'PROJECT_STATUS_01',
  Code_2 = 'PROJECT_STATUS_02',
  Code_3 = 'PROJECT_STATUS_03',
  Code_4 = 'PROJECT_STATUS_04',
  Code_5 = 'PROJECT_STATUS_05',
  Code_6 = 'PROJECT_STATUS_06',
}

export enum RecruitApplicationStatusCode {
  Code_1 = 'RECRUIT_APPLICATION_STATUS_01',
  Code_2 = 'RECRUIT_APPLICATION_STATUS_02',
  Code_3 = 'RECRUIT_APPLICATION_STATUS_03',
  Code_4 = 'RECRUIT_APPLICATION_STATUS_04',
  Code_5 = 'RECRUIT_APPLICATION_STATUS_05',
  Code_6 = 'RECRUIT_APPLICATION_STATUS_06',
  Code_7 = 'RECRUIT_APPLICATION_STATUS_07',
}

export enum TimeKeepingStatusCode {
  Done = 1,
  Pending = 2,
  Refuse = 3,
  Status = 5,
}

export enum TypeOnLeave {
  LEAVE_01 = 'LEAVE_01',
  LEAVE_02 = 'LEAVE_02',
  LEAVE_03 = 'LEAVE_03',
  LEAVE_04 = 'LEAVE_04',
  LEAVE_05 = 'LEAVE_05',
  LEAVE_06 = 'LEAVE_06',
  LEAVE_07 = 'LEAVE_07',
  LEAVE_08 = 'LEAVE_08',
  LEAVE_09 = 'LEAVE_09',
  LEAVE_10 = 'LEAVE_10',
}

export enum StatusTimeKeeping {
  DoSave = '0', // đang chấm công
  Pending = '2', // chờ phê duyệt
  Approve = '3', // phê duyệt
  Reject = '1', // từ chối
  NoRecord = '4', // chưa chấm
}
export enum StatusTimeKeepingCode {
  DoSave = 0, // đang chấm công
  Pending = 2, // chờ phê duyệt
  Approve = 3, // phê duyệt
  Reject = 1, // từ chối
  NoRecord = 4, // chưa chấm
}
export enum RoleForProject { // rowle member of project
  Pm = 'PM',
  Ba = 'BA',
  Deg = 'DESIGNER',
  Dev = 'DEV',
  Test = 'TESTER',
  // Pm = 1,
  // Ba = 2,
  // Deg = 3,
  // Dev = 4,
  // Test = 5,
}

export enum AdmPersonalized { //config header table
  Rate180 = 'RATE180_LIST',
  RateView180 = 'RATE180_VIEW_LIST',
  RateManagement = 'RATE_MANAGEMENT',
  RateApprove = 'RATE_APPROVE_LIST',
  RateApproveProject = 'RATE_PROJECT_APPROVE_LIST',
}

export enum CandidateStatusCode {
  New = 'RECRUIT_APPLICATION_STATUS_01',
  RateCv = 'RECRUIT_APPLICATION_STATUS_02',
  ContactCandidate = 'RECRUIT_APPLICATION_STATUS_03',
  Interview = 'RECRUIT_APPLICATION_STATUS_04',
  Salary = 'RECRUIT_APPLICATION_STATUS_05',
  EmployeeHandle = 'RECRUIT_APPLICATION_STATUS_06',
  Offer = 'RECRUIT_APPLICATION_STATUS_07',
}

export enum PerformanceReview {
  PERIOD = 'PERIOD',
  REVIEW_180 = 'REVIEW_180',
  REVIEW_90 = 'REVIEW_90',
}

export enum CourseStatus {
  PUBLIC = 'publish',
  PRIVATE = 'private',
}

export enum TitleMember { // danh mục chức danh
  generalManager = 'TITLE_01', // tổng
  businessManager = 'TITLE_02', // kinh doanh
  technicalDirector = 'TITLE_03', // kỹ thuật
  dataManager = 'TITLE_04', // dữ liệu
  operationDirector = 'TITLE_05', // vận hành
  financeDirector = 'TITLE_06', // tài chính
}
