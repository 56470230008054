<div (mouseenter)="mouseEnterMenu()" (mouseleave)="mouseLeaveMenu()" @toggleMenu [class]="classMenu">
  <div class="menu-header">
    <h1>HCM</h1>
    <a (click)="$event.preventDefault(); onStaticMenu()" class="left-menu-pin" href="#" title="Toggle Menu">
      <em [class]="isLock ? 'pi pi-lock lock-icon' : 'pi pi-lock-open lock-icon'"></em>
    </a>
  </div>
  <div class="seperator"></div>
  <div class="layout-menu-container">
    <ul class="layout-menu">
      <ng-container *ngFor="let item of items; let i = index">
        <li *ngIf="checkRole(item)" [class.active-menuitem]="item.active">
          <ng-container
            [ngTemplateOutletContext]="{ itemMenu: item }"
            [ngTemplateOutlet]="item.children && item.children.length > 0 ? sub : noSub"
          >
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<ng-template #noSub let-itemMenu="itemMenu">
  <a
    (click)="$event.preventDefault(); activeMenuItem(itemMenu)"
    [routerLink]="itemMenu.routerLink"
    class="menu-item-container"
    routerLinkActive="active-menuitem-routerlink">
    <span class="menuItem-text">{{ itemMenu.label | translate }}</span>
    <em *ngIf="itemMenu.icon" [class]="'layout-menuitem-icon ' + itemMenu.icon"></em>
  </a>
</ng-template>
<ng-template #sub let-i="index" let-itemMenu="itemMenu">
  <a (click)="$event.preventDefault(); activeMenuItem(itemMenu)" class="menu-item-container">
    <span class="menuItem-text">{{ itemMenu.label | translate }}</span>
    <div class="flex align-items-center justify-content-end">
      <em [ngClass]="{ 'rotated-icon': itemMenu.expanded }" class="chevron-icon pi pi-fw pi-angle-down"></em>
      <em *ngIf="itemMenu.icon" [class]="'layout-menuitem-icon ' + itemMenu.icon"></em>
    </div>
  </a>
  <ul *ngIf="itemMenu.expanded" @toggleMenu>
    <ng-container *ngFor="let item of itemMenu.children">
      <li *ngIf="checkRole(item)" [class.active-menuitem]="item.active">
        <ng-container
          [ngTemplateOutletContext]="{ itemMenu: item }"
          [ngTemplateOutlet]="item.children?.length > 0 ? sub : noSub"
        >
        </ng-container>
      </li>
    </ng-container>
  </ul>
</ng-template>
