import { Component, Injector, OnInit } from '@angular/core';
import { BaseActionComponent } from '@shared/components';
import { EmployeesManagementService } from '@features/employee/employee-list/services/employees-management.service';
import { CommonCategoryService } from '@features/administration/data-config/services/common-category.service';
import * as _ from 'lodash';
import { popResponseError } from '@cores/utils/functions';
import { DataTable } from '@cores/models/data-table.model';
import { UntypedFormGroup } from '@angular/forms';
import { PaginatorModel } from '@cores/models/paginator.model';
import { CategoryCode, ColumnDataType } from '@cores/utils/enums';
import { blockSpecialCharacter, onlyAlphabetAndNumber } from '@cores/utils/custom-validators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'advanced-search-employee',
  templateUrl: './advanced-search-employee.component.html',
  styleUrls: ['./advanced-search-employee.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearchEmployeeComponent extends BaseActionComponent implements OnInit {
  params = new UntypedFormGroup({});
  prevParams: any;
  paramsHasBeenSearched = true;
  dataTable: DataTable = {
    content: [],
    currentPage: 0,
    size: 5,
    totalElements: 0,
    totalPages: 0,
    first: 0,
    numberOfElements: 0,
  };
  cols = [
    { fieldName: 'code', headerName: 'Mã nhân viên', width: '118px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'fullName', headerName: 'Họ tên', width: '180px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'birthDate', headerName: 'Ngày sinh', width: '120px', visible: true, type: 'date' },
    { fieldName: 'departmentName', headerName: 'Phòng ban', width: '150px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'titleName', headerName: 'Chức danh', width: '150px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'positionName', headerName: 'Vị trí', width: '150px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'rankName', headerName: 'Cấp bậc', width: '150px', visible: true, type: ColumnDataType.Text },
  ];
  selectedEmployee: any[] = [];

  constructor(
    injector: Injector,
    private api: EmployeesManagementService,
    private commonCategoryApi: CommonCategoryService
  ) {
    super(injector, api);
    this.params = this.fb.group({
      employeeCode: [null, onlyAlphabetAndNumber()],
      fullName: [null, blockSpecialCharacter()],
      titleCode: '',
      rankCode: '',
      positionCode: '',
    });
  }

  select() {
    if (this.isLoading) {
      return;
    }
    this.refDialog.close(this.selectedEmployee);
  }

  pageChange(paginator: PaginatorModel) {
    this.dataTable.currentPage = paginator.page;
    this.dataTable.size = paginator.rows;
    this.dataTable.first = paginator.first;
    if (!this.paramsHasBeenSearched) {
      this.search(false);
      //Xoá params chưa được search khi chuyển trang
      this.params.reset();
      _.forOwn(this.prevParams, (value, key) => {
        this.params.get(key)?.setValue(value);
      });
    } else {
      this.search();
    }
    this.paramsHasBeenSearched = false;
  }

  search(firstPage?: boolean, searchWithPrevParams?: boolean) {
    //searchWithPrevParams dùng để bao trường hợp người dùng thay đổi params tìm kiếm, nhưng chưa ấn tìm kiếm. Vẫn có thể chuyển trang để xem data của bộ param cũ.
    if (firstPage) {
      this.dataTable.currentPage = 0;
      this.dataTable.first = 0;
    }
    this.isLoading = true;
    const paramsValue = searchWithPrevParams ? this.prevParams : _.get(this.params, 'value');
    const params = {
      page: this.dataTable.currentPage,
      size: this.dataTable.size,
      ...paramsValue,
    };
    this.api.search(params).subscribe({
      next: data => {
        this.dataTable = data;
        this.isLoading = false;
        this.prevParams = params;
        delete this.prevParams.size;
        delete this.prevParams.page;
        this.paramsHasBeenSearched = firstPage ? true : false; //firstPage chỉ bằng true khi ấn Tìm kiếm - dùng để phân biệt với action call API search khi chuyển trang
      },
      error: err => {
        this.messageService?.error(popResponseError(err));
        this.isLoading = false;
      },
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.params.valueChanges.subscribe(() => {
      this.paramsHasBeenSearched = false;
    });
    forkJoin([
      this.commonCategoryApi.getCategoryByCode(CategoryCode.TITLE),
      this.commonCategoryApi.getCategoryByCode(CategoryCode.RANK),
      this.commonCategoryApi.getCategoryByCode(CategoryCode.POSN),
    ]).subscribe({
      next: ([title, rank, posn]) => {
        this.droplistData.title = title?.commons;
        this.droplistData.rank = rank?.commons;
        this.droplistData.position = posn?.commons;
      },
      error: err => {
        this.messageService.error(popResponseError(err));
        this.isLoading = false;
      },
    });
    this.search();
  }

  reload() {
    this.params.setValue({
      employeeCode: null,
      fullName: null,
      titleCode: null,
      rankCode: null,
      positionCode: null,
    });
    this.paramsHasBeenSearched = true;
    this.search(true, false);
  }
}
