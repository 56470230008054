import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardService } from '@cores/services/guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule),
    canActivate: [GuardService],
  },
  {
    path: 'notfound',
    loadChildren: () => import('./shared/components/404-not-found/404-not-found.module').then(m => m.NotFoundModule),
    canActivate: [GuardService],
  },
  {
    path: 'login',
    loadChildren: () => import('./shared/components/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'not-allowed',
    loadChildren: () =>
      import('./shared/components/account-deactivated/account-deactivated.module').then(
        m => m.AccountDeactivatedModule
      ),
    canActivate: [GuardService],
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'notfound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
