<div class="panel-wrapper">
  <div class="flex justify-content-between header-wrapper">
    <p-checkbox (onChange)="toggleAll($event)" [(ngModel)]="isCheckAll" [binary]="true"> </p-checkbox>
    <button (click)="save()" label="{{ 'BUTTON.SAVE' | translate }}" pButton></button>
  </div>
  <div class="content-wrapper">
    <p-checkbox
      (onChange)="updateAllCheckbox()"
      *ngFor="let col of columnData"
      [(ngModel)]="col.visible"
      [binary]="true"
      [disabled]="col.inactive || false"
      [label]="col.headerName"
    ></p-checkbox>
  </div>
</div>
