import { Component, ElementRef, HostListener, Injector, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ScreenType, SessionKey } from 'src/app/core/utils/enums';
import { cleanDataForm, validateAllFormFields } from '@cores/utils/functions';
import { BaseService } from 'src/app/core/services/base.service';
import { DroplistModel } from '@cores/models/droplist.model';
import { ConfirmDialogComponent } from '@shared/components/confirm/confirm.component';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  template: ` <ng-content></ng-content>`,
})
export class BaseActionComponent extends BaseComponent implements OnDestroy {
  employeeCode = '';
  form = new UntypedFormGroup({});
  isLoading = false;
  today = new Date();
  readonly: boolean = false;
  onCloseConfirm = false;
  title: string | undefined;
  message = {
    create: {
      success: 'MESSAGE.ADD-SUCCESS',
      error: 'MESSAGE.ADD-ERROR',
    },
    update: {
      success: 'MESSAGE.UPDATE-SUCCESS',
      error: 'MESSAGE.UPDATE-ERROR',
    },
    approve: {
      success: 'MESSAGE.APPROVE-SUCCESS',
      error: 'MESSAGE.APPROVE-ERROR',
    },
    reject: {
      success: 'MESSAGE.APPROVE-SUCCESS',
      error: 'MESSAGE.APPROVE-SUCCESS',
    },
  };
  @ViewChild('dialog') dialog!: ElementRef;
  data: any;
  screenType: ScreenType | undefined;
  droplistData: DroplistModel = {};
  stateData: any;
  flagMouseDown = false;
  allowUpdate = true;
  protected el!: ElementRef;

  constructor(injector: Injector, public service: BaseService) {
    super(injector);
    this.init();
    this.employeeCode = this.currUser?.userInfo?.employeeCode || '';
    this.screenType = this.configDialog?.data?.screenType;
    this.readonly = this.screenType === ScreenType.Detail;
    this.configDialog.dismissableMask = false;
    this.configDialog.closeOnEscape = false;
    this.configDialog.showHeader = false;
    this.getDialogConfig();
  }

  @HostListener('document:keydown.escape') onEscape() {
    if (!this.onCloseConfirm) {
      this.cancel();
    }
  }

  // Chặn đóng dialog khi bôi đen
  @HostListener('window:mousedown') onMouseDown() {
    this.flagMouseDown = true;
  }

  @HostListener('window:mousemove') onDrag() {
    this.flagMouseDown = false;
  }

  @HostListener('window:click', ['$event.target'])
  clickDocument(event: any) {
    if (this.flagMouseDown) {
      let wrapper = document.querySelector('.p-component-overlay');
      if (event === wrapper) {
        this.cancel();
      }
    }
  }

  override init() {
    super.init();
    this.el = this.injector.get(ElementRef);
  }

  getDialogConfig() {
    if (this.configDialog) {
      if (this.screenType === ScreenType.Update) {
        this.title = this.translate.instant('DIALOG.HEADER.EDIT') + ' ' + this.configDialog?.data?.title;
      } else if (this.screenType === ScreenType.Create) {
        this.title = this.translate.instant('DIALOG.HEADER.ADD') + ' ' + this.configDialog?.data?.title;
      } else {
        this.title = this.translate.instant('DIALOG.HEADER.VIEW') + ' ' + this.configDialog?.data?.title;
      }
      this.data = this.configDialog?.data?.model;
      this.stateData = this.configDialog?.data?.state;
      this.employeeCode = this.configDialog?.data?.employeeCode
        ? this.configDialog?.data?.employeeCode
        : this.sessionService.getSessionData(SessionKey.UserProfile)?.userInfo?.employeeCode;
      this.droplistData = this.configDialog?.data?.dropListModel || {};
      this.allowUpdate = this.configDialog?.data?.allowUpdate;
    }
  }

  save() {
    if (this.loadingService.loading) {
      return;
    }
    const data = this.getDataForm();

    validateAllFormFields(this.form);
    if (this.form?.status === 'VALID') {
      this.messageService?.saveConfirm().subscribe(isConfirm => {
        if (isConfirm) {
          if (this.screenType == ScreenType.Create) {
            this.create(data);
          } else {
            this.update(data);
          }
        }
      });
    } else {

      validateAllFormFields(this.form);
    }
  }

  getDataForm() {

    return cleanDataForm(this.form);
  }

  create(data: any) {
    this.loadingService.start();
    this.service.create(data).subscribe({
      next: () => {
        this.messageService.success(this.message.create.success);
        this.refDialog.close(true);
        this.loadingService.complete();
      },
      error: err => {
        this.messageService.error(err.error.message);
        this.loadingService.complete();
      },
    });
  }

  update(data: any) {
    this.loadingService.start();
    this.service.update(data).subscribe({
      next: () => {
        this.messageService.success(this.message.update.success);
        this.refDialog.close(true);
        this.loadingService.complete();
      },
      error: err => {
        this.messageService.error(err.error.message);
        this.loadingService.complete();
      },
    });
  }

  cancel() {
    if (this.configDialog) {
      if (this.form.dirty) {

        this.onCloseConfirm = true;
        const dialog = this.dialogService?.open(ConfirmDialogComponent, {
          showHeader: false,
          baseZIndex: 99999,
          data: {
            title: this.translate.instant('DIALOG.HEADER.CONFIRM'),
            message: this.translate.instant('DIALOG.MESSAGE.CLOSE-CONFIRM'),
            buttonTitle: 'BUTTON.CLOSE',
          },
        });
        dialog?.onClose.subscribe({
          next: isSuccess => {
            if (isSuccess) {
              this.refDialog.close();
            }
            this.onCloseConfirm = false;
          },
        });
      } else {
        this.refDialog.close();
      }
    } else {
      this.location.back();
    }
  }

  changeToEditScreen() {
    this.screenType = ScreenType.Update;
    this.readonly = false;
    this.getDialogConfig();
  }
}
