<span *ngIf="readonly && options" class="readonly-view">
  <div class="readonly-label">{{ label | translate }}</div>
  <div class="readonly-content">{{ control.value || '' }}</div>
</span>
<span *ngIf="!readonly" class="p-float-label">
  <p-autoComplete
    #autoComplete
    (completeMethod)="completeMethod($event)"
    (onFocus)="autoComplete.handleDropdownClick($event)"
    [delay]="100"
    [formControl]="control"
    [multiple]="isMultiSelect"
    [suggestions]="filteredOptions"
    appendTo="body"
    field="name"
  >
  </p-autoComplete>
  <label class="block">
    <span *ngIf="showLabel"> {{ label | translate }}</span>
    <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
  >

  <div class="relative b-errors">
    <ng-container *ngIf="errors">
      <small class="p-error error-msg block fadeinup animation-duration-300">
        <span class="block">{{ getError().key | translate: getError().required }}</span>
      </small>
    </ng-container>
  </div>
</span>
