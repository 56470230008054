import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage } from '@cores/utils/functions';
import { REGEX_CONSTANT } from '@cores/utils/constants';
import { TranslateService } from '@ngx-translate/core';
import { minAtTheMoment } from '@cores/utils/custom-validators';
import * as moment from 'moment/moment';

@Component({
  selector: 'lp-input-time',
  templateUrl: './lp-input-time.component.html',
  styleUrls: ['./lp-input-time.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputTimeComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputTimeComponent),
      multi: true,
    },
  ],
})
export class LPInputTimeComponent implements Validator, ControlValueAccessor {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() showLabel: boolean = true;
  @Input() required?: boolean | string;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = true;
  @Input() border: boolean = true;
  @Input() separator: string = ':';
  absControl!: AbstractControl;
  control = new UntypedFormControl(null);
  initComp: boolean = false;
  hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  minutes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
  ];
  hour: number = 0;
  minute: number = 0;

  constructor(
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        const val = value.substring(0, 2) + this.separator + value.substring(2, 4);
        if (val === this.separator) {
          this.onChange('');
        } else {
          this.onChange(val);
        }
      }
    });
  }

  _minDate: any;

  //minDate dùng để check trường hợp validate thời gian nhập vào phải lớn hơn thời điểm hiện tại
  get minDate(): Date {
    return this._minDate;
  }

  @Input() set minDate(value: string | Date | undefined) {
    if (typeof value === 'string') {
      this._minDate = new Date(value);
    } else if (value instanceof Date) {
      this._minDate = value;
    }
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  setHour(hour: number) {
    this.hour = hour;
    this.updateHour();
  }

  setMinute(minute: number) {
    this.minute = minute;
    this.updateMinute();
  }

  updateHour() {
    let result = this.hour.toString().padStart(2, '0') + this.control.value.substring(2, 4);
    this.onChange(result);
    this.control.setValue(result);
  }

  updateMinute() {
    let result = this.control.value.substring(0, 2) + this.minute.toString().padStart(2, '0');
    this.control.setValue(result);
  }

  onBlur() {
    const tmpHour = this.control.value.substring(0, 2);
    const tmpMinute = this.control.value.substring(2, 4);
    this.setHour(parseInt(tmpHour));

    this.setMinute(parseInt(tmpMinute));
  }

  onKeyDown(event: any) {
    if ([37, 38, 39, 40].includes(event.keyCode)) {
      event.preventDefault();
    }
    let selectionEnd = event.target.selectionEnd;
    switch (event.keyCode) {
      case 38:
        if (selectionEnd < 3) {
          this.increaseHour(event);
        } else {
          this.increaseMin(event);
        }
        break;
      case 40:
        if (selectionEnd < 3) {
          this.decreaseHour(event);
        } else {
          this.decreaseMin(event);
        }
        break;
      default:
        break;
    }
  }

  increaseHour(event: any) {
    if (this.hour < 23) {
      this.hour++;
      this.updateHour();
      event.target.setSelectionRange(0, 2);
    } else {
      this.hour = 0;
      this.updateHour();
      event.target.setSelectionRange(0, 2);
    }
  }

  increaseMin(event: any) {
    if (this.minute < 59) {
      this.minute++;
      this.updateMinute();
      event.target.setSelectionRange(3, 5);
    } else {
      this.minute = 0;
      this.updateMinute();
      event.target.setSelectionRange(3, 5);
    }
  }

  decreaseHour(event: any) {
    if (this.hour > 0) {
      this.hour--;
      this.updateHour();
      event.target.setSelectionRange(0, 2);
    } else {
      this.hour = 23;
      this.updateHour();
      event.target.setSelectionRange(0, 2);
    }
  }

  decreaseMin(event: any) {
    if (this.minute > 0) {
      this.minute--;
      this.updateMinute();
      event.target.setSelectionRange(3, 5);
    } else {
      this.minute = 59;
      this.updateMinute();
      event.target.setSelectionRange(3, 5);
    }
  }

  onKeyUp(event: any) {
    //keyCode 37 - ArrowLeft
    //keyCode 38 - ArrowUp
    //keyCode 39 - ArrowRight
    //keyCode 40 - ArrowDown
    if (event.keyCode === 37) {
      event.target.setSelectionRange(0, 2);
    }

    if (event.keyCode === 39) {
      event.target.setSelectionRange(3, 5);
    }
  }

  onInput(event: any) {
    const currentValue = event.target.value;
    const tmpHour = currentValue.split(this.separator)[0];
    const tmpMinute = currentValue.split(this.separator)[1];
    if (parseInt(tmpHour) > 23 && event.target.value.length === 5) {
      this.setHour(23);
      this.updateHour();
      event.target.setSelectionRange(0, 2);
    }
    if (parseInt(tmpMinute) > 59 && event.target.value.length === 5) {
      this.setMinute(59);
      this.updateMinute();
      event.target.setSelectionRange(3, 5);
    }
  }

  onClick(event: any) {
    let selectionEnd = event.target.selectionEnd;
    if (selectionEnd < 3) {
      event.target.setSelectionRange(0, 2);
    } else {
      event.target.setSelectionRange(3, 5);
    }
  }

  onChange = (value: any) => {};

  onTouched = () => {};

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  writeValue(value: string): void {
    this.control.setValue(value, { emitEvent: false });
    this.initComp = true;
    if (this.absControl) {
      this.absControl.markAsPristine();
    }
  }

  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    if (!REGEX_CONSTANT.timeFormat.test(control.value)) {
      return { pattern: {} };
    }
    if (this.minDate) {
      if (
        moment(this.minDate, 'DD/MM/YYYY', true).isValid() ||
        moment(this.minDate, 'YYYY-MM-DD', true).isValid() ||
        (moment(this.minDate).isValid() && typeof this.minDate !== 'string')
      ) {
        const dateValue = moment(this.minDate, 'DD/MM/YYYY', true).isValid()
          ? moment(this.minDate, 'DD/MM/YYYY')
          : moment(this.minDate, 'YYYY-MM-DD');
        if (minAtTheMoment(dateValue, control.value)) {
          return minAtTheMoment(dateValue, control.value);
        }
      }
    }
    return null;
  }
}
