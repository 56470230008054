import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnModel } from '@cores/models/data-table.model';
import * as _ from 'lodash';

@Component({
  selector: 'column-personalize-panel',
  templateUrl: './column-personalize-panel.component.html',
  styleUrls: ['./column-personalize-panel.component.scss'],
})
export class ColumnPersonalizePanelComponent implements OnInit {
  @Input() columnData: ColumnModel[] = [];
  @Output() doSave: EventEmitter<any> = new EventEmitter<any>();
  isCheckAll: boolean = false;

  ngOnInit() {
    this.updateAllCheckbox();
  }

  toggleAll(event: any) {
    let isCheck = event.checked;
    _.forEach(this.columnData, e => {
      if (e.inactive) {
        return;
      }
      e.visible = isCheck;
    });
  }

  save() {
    this.doSave.emit();
  }

  updateAllCheckbox() {
    if (_.find(this.columnData, e => e.visible === false)) {
      this.isCheckAll = false;
    } else {
      this.isCheckAll = true;
    }
  }
}
