import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@cores/services/base.service';
import { environment } from '@env';
import { map, Observable, of, Subject } from 'rxjs';
import { DataTable } from '@cores/models/data-table.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class EmployeesManagementService extends BaseService {
  clearCache = false;
  onTabViewChange: Subject<void> = new Subject<void>();

  constructor(http: HttpClient) {
    super(http, `${environment.endpoint_url}/employee`);
  }

  override create(data: any): Observable<any> {
    this.clearCache = true;
    return this.http.post<any>(`${this.baseUrl}/save`, data);
  }

  getCommonInfo(employeeCode: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/tab-info/${employeeCode}`);
  }

  override delete(id: string | number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  getEmployeeDroplist(): Observable<any> {
    if (this.clearCache) {
      this.state.delete('employee-droplist');
    }
    const state = this.state.get('employee-droplist');
    if (state) {
      return of(state);
    }
    return this.http.get<DataTable>(`${this.baseUrl}`, { params: { size: 100000 } }).pipe(
      map((data: DataTable) => {
        let orderedData = _.orderBy(data.content, ['employeeCode'], ['desc']);
        let res = _.map(orderedData, (e: any) => {
          return {
            code: e.code,
            name: `${e.code} - ${e.fullName}`,
          };
        });
        this.state.set('employee-droplist', res);
        this.clearCache = false;
        return res;
      })
    );
  }

  // department management
  getManagementInfomation(employeeCode: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/management-info/${employeeCode}`);
  }
  // department management empolyee
  getManagementEmployee(dept: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/employee-by-dept/${dept}`);
  }
  // update management employee
  sendApiUpdate(data: any, code: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/update-employee-manager/${code}`, data);
  }
  //get all emplyee active
  getListAllEmployee(): Observable<any> {
    return this.http.get(`${environment.endpoint_url}/employee?size=10000&&isActive=1&checkOrg=0`);
  }
  //get code from generate
  getCodeGenerate(): Observable<any> {
    return this.http.get(`${environment.endpoint_url}/employee/generate-code`);
  }
}
