import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { Observable, of, tap } from 'rxjs';
import { MasterDataType } from '@cores/utils/enums';
import { BaseService } from '@cores/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class MasterDataApi extends BaseService {
  constructor(http: HttpClient) {
    super(http, `${environment.endpoint_url}`);
  }

  getMasterData(type: MasterDataType, params?: any): Observable<any> {
    if (this.state.get(type)) {
      return of(this.state.get(type));
    } else {
      return this.http
        .get<any>(`${environment.endpoint_url}/${type}`, { params: { page: 0, size: 12000, ...params } })
        .pipe(
          tap(response => {
            this.state.set(type, response);
            return response;
          })
        );
    }
  }
}
