<div class="wrapper">
  <p-inputSwitch
    [falseValue]="falseValue"
    [formControl]="control"
    [readonly]="readonly"
    [trueValue]="trueValue"
  ></p-inputSwitch>

  <label class="block">
    <span *ngIf="showLabel"> {{ label | translate }}</span>
  </label>
</div>
