import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'tableFieldPipe',
})
export class TableFieldPipe implements PipeTransform {
  transform(value: any, args?: string): any {
    return _.get(value, args!, '---');
  }
}
