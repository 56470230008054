import { BaseComponent } from './base.component';
import { BaseActionComponent } from './base-action.component';
import { BaseTableComponent } from './base-table.component';
import { LoadingComponent } from './loading/loading.component';
import { ConfirmDialogComponent } from './confirm/confirm.component';
import { ColumnPersonalizePanelComponent } from './column-personalize-panel/column-personalize-panel.component';
import { AdvancedSearchEmployeeComponent } from './advanced-search-employee/advanced-search-employee.component';

//IMPORT LPTECH COMPONENT
import { LPInputTextComponent } from './lp-input-text/lp-input-text.component';
import { LPInputNumberComponent } from './lp-input-number/lp-input-number.component';
import { LPInputDateComponent } from './lp-input-date/lp-input-date.component';
import { LPInputPhoneComponent } from './lp-input-phone/lp-input-phone.component';
import { LPInputTextareaComponent } from './lp-input-textarea/lp-input-textarea.component';
import { LPInputEditorComponent } from './lp-input-editor/lp-input-editor.component';
import { LPInputRadioButtonComponent } from './lp-input-radio-button/lp-input-radio-button.component';
import { LPInputCheckboxComponent } from './lp-input-checkbox/lp-input-checkbox.component';
import { LPInputTreeSelectComponent } from './lp-input-tree-select/lp-input-tree-select.component';
import { LPInputSelectComponent } from './lp-input-select/lp-input-select.component';
import { LPInputSwitchComponent } from './lp-input-switch/lp-input-switch.component';
import { TableActionPanelComponent } from './table-action-panel/table-action-panel.component';
import { LPProgressBarComponent } from './lp-progress-bar/lp-progress-bar.component';
import { LPInputAutocompleteComponent } from './lp-input-autocomplete/lp-input-autocomplete.component';
import { LPInputFilesComponent } from './lp-input-files/lp-input-files.component';
import { LPInputTimeComponent } from './lp-input-time/lp-input-time.component';
import { AdvancedSearchEmployeeSingleComponent } from './advanced-search-employee-single/advanced-search-employee-single.component';
import { LPTableComponent } from '@shared/components/lp-table/lp-table.component';

export const components = [
  BaseComponent,
  LoadingComponent,
  ConfirmDialogComponent,
  BaseTableComponent,
  BaseActionComponent,
  AdvancedSearchEmployeeComponent,
  AdvancedSearchEmployeeSingleComponent,
  LPInputTextComponent,
  LPInputNumberComponent,
  LPInputDateComponent,
  LPInputPhoneComponent,
  LPInputTextareaComponent,
  LPInputEditorComponent,
  LPInputRadioButtonComponent,
  LPInputCheckboxComponent,
  LPInputTreeSelectComponent,
  LPInputSelectComponent,
  LPInputSwitchComponent,
  TableActionPanelComponent,
  ColumnPersonalizePanelComponent,
  LPProgressBarComponent,
  LPInputAutocompleteComponent,
  LPInputFilesComponent,
  LPInputTimeComponent,
  LPTableComponent,
];

export * from './base.component';
export * from './loading/loading.component';
export * from './confirm/confirm.component';
export * from './base-table.component';
export * from './base-action.component';
