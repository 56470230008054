import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

export enum DateFormat {
  Date = 'dd/MM/yyyy',
  DateHour = 'dd/MM/yyyy HH',
  DateTime = 'dd/MM/yyyy HH:mm',
}

export enum MapKeyType {
  String,
  Number,
  Boolean,
}

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {
  private datePipe: DatePipe = new DatePipe('en-US');
  private decimalPipe: DecimalPipe = new DecimalPipe('en-US');

  constructor(private translate: TranslateService) {}

  transform(value: any, arg?: any): any {
    if (!arg || value === undefined || value === null) {
      return '---';
    }
    let type: string = arg;
    let param = '';
    if (arg.indexOf(':') !== -1) {
      type = arg.substring(0, arg.indexOf(':'));
      param = arg.substring(arg.indexOf(':') + 1, arg.length);
    } else {
      param = DateFormat.Date;
    }

    switch (type) {
      case 'date':
        return this.datePipe.transform(value, param) ?? '---';
      case 'currency':
        const currency = this.decimalPipe.transform(value, '1.0-2');
        return currency ? currency + ' VND' : '---';
      case 'number':
        const number = this.decimalPipe.transform(value, '1.0-2');
        return number ? number : '---';
      case 'array':
        const array = value.join(', ');
        return array ? array : '---';
      case 'yn':
        return this.translate.instant(!!value ? 'button.yes' : 'button.no');
      default:
        return this.translate.instant(value?.toString());
    }
  }
}
