import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[chipView]',
})
export class ChipViewDirective implements OnChanges {
  @Input() value: any;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!_.isEqual(changes['value'].currentValue, changes['value'].previousValue)) {
      if (Array.isArray(this.value)) {
        let html = _.reduce(
          this.value,
          (result, currValue) => {
            return result + `<span class="chip-view--item">${currValue}</span>`;
          },
          ''
        );
        this.el.nativeElement.innerHTML = `<div class="chip-view--container">` + html + `</div>`;
      } else {
        this.el.nativeElement.innerHTML = `${this.value}`;
      }
    }
  }
}
