import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@cores/services/base.service';
import { environment } from '@env';
import { Observable, of, tap } from 'rxjs';
import { CommonCategoryModel } from '@models/common-category.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CommonCategoryService extends BaseService {
  clearCache = false;

  constructor(http: HttpClient) {
    super(http, `${environment.endpoint_url}/common-category`);
  }

  getCategoryByCode(code: string): Observable<any> {
    if (this.clearCache) {
      this.state.delete(code);
    }
    const state = this.state.get(code);
    if (state) {
      return of(state);
    }
    return this.http.get(`${this.baseURL}/commons-by-code`, { params: { commonCategoryCode: code } }).pipe(
      tap(response => {
        this.state.set(code, _.cloneDeep(response));
        this.clearCache = false;
      })
    );
  }

  getDepartmentCategory(): Observable<any> {
    if (this.clearCache) {
      this.state.delete('DEPARTMENT');
    }
    const state = this.state.get('DEPARTMENT');
    if (state) {
      return of(state);
    }
    return this.http.get(`${environment.endpoint_url}/organization/departments`).pipe(
      tap(response => {
        this.state.set('DEPARTMENT', _.cloneDeep(response));
        this.clearCache = false;
      })
    );
  }

  getMenuCategory(): Observable<any> {
    if (this.clearCache) {
      this.state.delete('MENU');
    }
    const state = this.state.get('MENU');
    if (state) {
      return of(state);
    }
    return this.http.get(`${environment.endpoint_url}/adm/parent-module`).pipe(
      tap(response => {
        this.state.set('MENU', _.cloneDeep(response));
        this.clearCache = false;
      })
    );
  }

  // getCategoryByCode(code: string): Observable<any> {
  //   return this.http.get(`${this.baseURL}/commons-by-code`, {
  //     params: {
  //       commonCategoryCode: code,
  //     },
  //   });
  // }

  updateCommonCategory(code: string, body: CommonCategoryModel): Observable<any> {
    this.clearCache = true;
    return this.http.put(`${this.baseURL}/${code}`, body);
  }

  override create(data: any): Observable<any> {
    this.clearCache = true;
    return this.http.post(`${this.baseURL}`, data);
  }
}
