import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as _ from 'lodash';

@Component({
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  data: any;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit() {
    this.data = _.get(this.config, 'data');
  }
  confirm(isConfirm: boolean) {
    this.ref.close(isConfirm);
  }
  cancel() {
    this.ref.close();
  }
}
