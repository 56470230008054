import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage } from '@cores/utils/functions';
import { TranslateService } from '@ngx-translate/core';
import { TreeData, TreeFlatData } from '@shared/models/common-category.model';
import * as _ from 'lodash';

@Component({
  selector: 'lp-input-tree-select',
  templateUrl: './lp-input-tree-select.component.html',
  styleUrls: ['./lp-input-tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputTreeSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputTreeSelectComponent),
      multi: true,
    },
  ],
})
export class LPInputTreeSelectComponent implements Validator, ControlValueAccessor, AfterViewInit {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() filter: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() keyData: string = 'data';
  @Input() emptyMessage: string = '';
  @Input() selectionMode: string = 'single';
  @Input() readonly: boolean = false;
  @Input() border: boolean = false;
  @Input() metaKeySelection: boolean = false;
  @Input() showClear: boolean = false;
  @Output() nodeSelect = new EventEmitter<any>();

  absControl!: AbstractControl;
  control = new UntypedFormControl();

  // nodes: any = [];

  constructor(private el: ElementRef<HTMLElement>, private translate: TranslateService) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        this.onChange(value?.[this.keyData]);
      }
    });
  }

  _options: TreeData[] = [];

  get options() {
    return this._options;
  }

  @Input() set options(value: TreeFlatData[]) {
    this._options = this.getParent(value);
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  ngAfterViewInit() {}

  getOptionsLabel(value: TreeData) {
    if (!value) {
      return '---';
    }
    const label = this.findInTree(value.code, this.options)?.name;
    if (typeof label == 'string') {
      return label;
    }
    return '---';
  }

  //
  // onTreePanelShow() {
  //   const closeButton: any = this.el.nativeElement.querySelector('button.p-treeselect-close');
  //   closeButton.setAttribute('type', 'button');
  // }

  onChange = (value: any) => {};

  getParent(data: TreeData[]) {
    _.forEach(data, x => {
      x.label = x.name;
      x.data = x.code;
    });
    let parent = _.filter(data, item => !item.parentCode);
    _.forEach(parent, itemParent => {
      this.getChildren(data, itemParent);
    });
    return parent;
  }

  getChildren(data: TreeData[], itemParent: TreeData) {
    const children = _.filter(data, item => item.parentCode === itemParent.code);
    itemParent.children = children;
    if (children.length > 0) {
      _.forEach(itemParent.children, ele => {
        this.getChildren(data, ele);
      });
    }
  }

  onNodeSelect(event: any) {
    this.nodeSelect.emit(event);
  }

  onTouched = () => {};

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  writeValue(value: any): void {
    if (value) {
      value = this.findInTree(value, this.options);
    }
    this.control.setValue(value, { emitEvent: false });
    if (this.absControl) {
      this.absControl.markAsPristine();
    }
  }

  findInTree(code: any, tree: any): any {
    if (Array.isArray(tree)) {
      for (let treeIre of tree) {
        let temp = this.findInTree(code, treeIre);
        if (!_.isEmpty(temp)) {
          return temp;
        }
      }
      return {};
    } else {
      if (tree[this.keyData] === code) {
        return tree;
      } else {
        for (let child of tree?.children) {
          let tmp = this.findInTree(code, child);
          if (!_.isEmpty(tmp)) {
            return tmp;
          }
        }
      }
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    return null;
  }
}
