<ul>
  <li (click)="onDetailClick()" *ngIf="detail">
    <div class="item-wrapper">
      <em class="lp-detail"></em>
      {{ 'BUTTON.VIEW-DETAIL' | translate }}
    </div>
  </li>
  <li (click)="onEmailClick()" *ngIf="email">
    <div class="item-wrapper">
      <em class="lp-email"></em>
      {{ 'Email' | translate }}
    </div>
  </li>
  <li (click)="onBoardingClick()" *ngIf="onboarding">
    <div class="item-wrapper" style="color: #378100">
      <em class="action-cell pi pi-check"></em>
      {{ 'BUTTON.ONBOARDING' | translate }}
    </div>
  </li>

  <li (click)="onRefuseClick()" *ngIf="refuse">
    <div class="item-wrapper" style="color: #a33134">
      <em class="action-cell pi pi-times"></em>
      {{ 'BUTTON.DECLINED' | translate }}
    </div>
  </li>

  <li (click)="onBrowseClick()" *ngIf="browse">
    <div class="item-wrapper">
      <em class="action-cell pi pi-check" style="color: #56ca00"></em>
      {{ 'BUTTON.APPROVE-LIST' | translate }}
    </div>
  </li>

  <li (click)="onViewClick()" *ngIf="view">
    <div class="item-wrapper">
      <em class="pi pi-fw pi-eye"></em>
      {{ 'BUTTON.QUICK-VIEW' | translate }}
    </div>
  </li>

  <li (click)="onActionAssignClick()" *ngIf="actionAssign">
    <div class="item-wrapper">
      <em class="lp-assign" style="color: #ff4c51"></em>
      {{ 'BUTTON.ASSIGN-ACTION' | translate }}
    </div>
  </li>

  <li (click)="onRoleAssignClick()" *ngIf="roleAssign">
    <div class="item-wrapper">
      <em class="lp-assign" style="color: #ff4c51"></em>
      {{ 'BUTTON.ROLE-ACTION' | translate }}
    </div>
  </li>

  <li (click)="onLockClick()" *ngIf="lock">
    <div class="item-wrapper">
      <em class="pi pi-fw pi-lock" style="color: #ff4c51"></em>
      {{ 'BUTTON.LOCK-ACTION' | translate }}
    </div>
  </li>

  <li (click)="onUnlockClick()" *ngIf="unlock">
    <div class="item-wrapper">
      <em class="pi pi-fw pi-unlock" style="color: #56ca00"></em>
      {{ 'BUTTON.UNLOCK-ACTION' | translate }}
    </div>
  </li>

  <li (click)="onUserAssignClick()" *ngIf="userAssign">
    <div class="item-wrapper">
      <em class="lp-assign-user" style="color: #ff4c51"></em>
      {{ 'BUTTON.ASSIGN-USER' | translate }}
    </div>
  </li>

  <li (click)="onEditClick()" *ngIf="edit">
    <div class="item-wrapper">
      <em class="lp-pencil"></em>
      {{ 'BUTTON.UPDATE' | translate }}
    </div>
  </li>

  <li (click)="onDownloadClick()" *ngIf="download">
    <div class="item-wrapper">
      <em class="lp-download-document"></em>
      {{ 'BUTTON.DOWNLOAD' | translate }}
    </div>
  </li>

  <li (click)="onDeleteClick()" *ngIf="delete">
    <div class="item-wrapper">
      <em class="action-cell lp-rubbish-bin" style="color: #ff4c51"></em>
      {{ 'BUTTON.DELETE' | translate }}
    </div>
  </li>
</ul>
