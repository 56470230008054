import { Observable } from 'rxjs';
import { Component, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Action, ScreenType, SessionKey } from 'src/app/core/utils/enums';
import { ColumnModel, DataTable } from 'src/app/core/models/data-table.model';
import { BaseService } from 'src/app/core/services/base.service';
import { PaginatorModel } from 'src/app/core/models/paginator.model';
import { ActionConfig } from 'src/app/core/models/action-config.model';
import * as _ from 'lodash';
import { DroplistModel } from '@cores/models/droplist.model';
import { popResponseError, removeParamNullOrUndefined, isEmpty } from '@cores/utils/functions';
import { PersonalizeApi } from '@cores/services/personalize.api';
import { Paginator } from 'primeng/paginator';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  template: ` <ng-content></ng-content>`,
  providers: [BaseService],
})
export class BaseTableComponent<M> extends BaseComponent implements OnDestroy {
  @Input() employeeCode = '';
  isChangeParam: boolean = false;
  today = new Date();
  isPersonalized: boolean = false;

  stateData: any;
  propData: any;
  dataTable: DataTable = {
    content: [],
    currentPage: 0,
    size: 10,
    totalElements: 0,
    totalPages: 0,
    first: 0,
    numberOfElements: 0,
    per_page: 10,
  };

  cols: ColumnModel[] = [];
  configAction: ActionConfig | undefined;
  droplistData: DroplistModel = {};
  prevParams: any;
  params: M | UntypedFormGroup | undefined;
  fileNameExcel = 'list-data.xlsx';
  screenCode!: string;
  screenName!: string;
  paramsHasBeenSearched: boolean = true; //Xác định xem những param search bị thay đổi đã được search hay chưa
  @ViewChild('paginator', { static: true }) paginator: Paginator | undefined;
  protected personalizedApi!: PersonalizeApi;

  constructor(override injector: Injector, protected serviceBase: BaseService) {
    super(injector);
    this.initConfigAction();
  }

  // ngOnInit() {
  // }

  ngAfterContentInit() {
    if (this.params instanceof UntypedFormGroup) {
      this.params?.valueChanges.subscribe((data: any) => {
        const prevParams = _.cloneDeep(this.prevParams);
        _.forEach(data, (value, key) => {
          if (isEmpty(data[key])) {
            data[key] = null;
          }
        });
        delete prevParams?.limit;
        delete prevParams?.offset;
        this.isChangeParam = _.differenceWith(_.toPairs(data), _.toPairs(prevParams), _.isEqual).length > 0;
      });
    }
  }

  override init() {
    super.init();
    this.personalizedApi = this.injector.get(PersonalizeApi);
  }

  onActionRow(action: any) {
    if (!this.checkRole(action.type)) {
      this.messageService.error('Người dùng không có quyền thực hiện chức năng này');
      return;
    }
    if (action.type === Action.Update) {
      this.onUpdate(action.item);
    } else if (action.type === Action.Delete) {
      this.onDelete(action.item);
    }
  }

  initConfigAction() {}

  mapState() {}

  search(firstPage?: boolean) {
    const params = this.mapDataSearch(!!firstPage);
    this.loadingService.start();
    this.serviceBase.search(params).subscribe({
      next: data => {
        this.dataTable = data;
        this.loadingService.complete();
        this.prevParams = params;
        this.isChangeParam = false;
        delete this.prevParams.size;
        delete this.prevParams.page;
        this.paramsHasBeenSearched = !!firstPage; //firstPage chỉ bằng true khi ấn Tìm kiếm - dùng để phân biệt với action call API search khi chuyển trang
      },
      error: err => {
        this.messageService?.error(popResponseError(err));
        this.loadingService.complete();
      },
    });
  }

  pageChange(paginator: PaginatorModel) {
    //
    this.dataTable.currentPage = paginator.page;
    this.dataTable.size = paginator.rows;
    this.dataTable.first = paginator.first;
    if (!this.paramsHasBeenSearched) {
      this.search(false);
      //Xoá params chưa được search khi chuyển trang
      if (this.params instanceof UntypedFormGroup) {
        this.params.reset();
        _.forOwn(this.prevParams, (value, key) => {
          if (this.params instanceof UntypedFormGroup) {
            this.params.get(key)?.setValue(value);
          }
        });
      }
    } else {
      this.search();
    }
    this.paramsHasBeenSearched = false;
  }

  mapDataSearch(firstPage: boolean): M {
    if (firstPage) {
      this.dataTable.currentPage = 0;
      this.dataTable.first = 0;
    } else {
      if (this.params instanceof UntypedFormGroup) {
        this.params.patchValue(this.prevParams, { emitEvent: false });
      } else {
        this.params = _.cloneDeep(this.prevParams);
      }
    }
    return {
      page: this.dataTable.currentPage,
      size: this.dataTable.size,
      ...(this.params instanceof UntypedFormGroup ? this.params.getRawValue() : this.params),
    };
  }

  getPersonalized(screenCode: string) {
    if (isEmpty(_.get(this.currUser, 'personalizedInfo.personalized')[screenCode])) {
      const colPersonalized = _.get(this.currUser, 'personalizedInfo.personalizedDefault')[screenCode];
      const colDescription = _.get(colPersonalized, 'description');
      if (colDescription) {
        return JSON.parse(_.get(colPersonalized, 'description'));
      }
      return null;
    } else {
      const colPersonalized = _.get(this.currUser, 'personalizedInfo.personalized')[screenCode];
      return JSON.parse(_.get(colPersonalized, 'description'));
    }
  }

  initDialogData(): Observable<any> {
    return new Observable(observable => {
      observable.next();
    });
  }

  onQuickAction(event?: any, element?: any, allowUpdate = true) {
    if (event.target) {
      //fix Tạm thời đợi thay đổi hết table thì remove
      if (event.target.classList.contains('action-col') || event.target.classList.contains('action-cell')) {
        return;
      }
    }

    if (this.loadingService.loading || !this.configAction?.component) {
      return;
    }
    this.loadingService.start();
    const initDataSubcription = this.initDialogData().subscribe({
      next: value => {
        this.loadingService.complete();
        const dialog = this.dialogService?.open(this.configAction!.component, {
          showHeader: false,
          width: this.configAction!.dialog?.width || '85%',
          data: {
            model: element ? element : [],
            state: value,
            employeeCode: this.employeeCode,
            dropListModel: this.droplistData,
            allowUpdate: allowUpdate,
            screenType: ScreenType.Detail,
            title: this.translate.instant(this.configAction!.title).toLowerCase(),
          },
        });
        dialog?.onClose.subscribe({
          next: isSuccess => {

            initDataSubcription.unsubscribe();
            if (isSuccess) {

              this.search();
            }
          },
        });
      },
      error: err => {
        this.messageService.error(popResponseError(err));
        this.loadingService.complete();
      },
    });
  }

  onCreate() {
    if (!this.configAction?.component) {
      return;
    }
    this.loadingService.start();
    const initDataSubcription = this.initDialogData().subscribe({
      next: (value: any) => {
        this.loadingService.complete();
        const dialog = this.dialogService?.open(this.configAction!.component, {
          showHeader: false,
          width: this.configAction!.dialog?.width || '85%',
          data: {
            state: value,
            employeeCode: this.employeeCode,
            dropListModel: this.droplistData,
            screenType: ScreenType.Create,
            title: this.translate.instant(this.configAction!.title).toLowerCase(),
          },
        });
        dialog?.onClose.subscribe({
          next: isSuccess => {
            initDataSubcription.unsubscribe();
            if (isSuccess) {

              this.search();
            }
          },
        });
      },
      error: err => {
        this.messageService.error(popResponseError(err));
        this.loadingService.complete();
      },
    });
  }

  onUpdate(item: any) {
    if (!this.configAction?.component) {
      return;
    }
    this.loadingService.start();
    const initDataSubcription = this.initDialogData().subscribe({
      next: (value: any) => {
        this.loadingService.complete();
        const dialog = this.dialogService?.open(this.configAction!.component, {
          showHeader: false,
          width: this.configAction!.dialog?.width || '85%',
          data: {
            state: value,
            model: item,
            employeeCode: this.employeeCode,
            dropListModel: this.droplistData,
            screenType: ScreenType.Update,
            title: this.translate.instant(this.configAction!.title).toLowerCase(),
          },
        });
        dialog?.onClose.subscribe({
          next: isSuccess => {
            initDataSubcription.unsubscribe();
            if (isSuccess) {

              this.search();
            }
          },
        });
      },
      error: err => {
        this.messageService.error(popResponseError(err));
        this.loadingService.complete();
      },
    });
  }

  onDelete(code: any, elementType: string = 'COMMON.RECORD') {
    if (this.loadingService.loading) {
      return;
    }
    this.messageService.deleteConfirm(elementType).subscribe(isConfirm => {
      if (isConfirm) {
        this.loadingService.start();
        this.serviceBase.delete(code).subscribe({
          next: value => {
            this.loadingService.complete();
            this.messageService.success('MESSAGE.DELETE-SUCCESS');
            if (this.dataTable.numberOfElements === 1) {
              this.dataTable.currentPage--;
            }
            this.reload();
          },
          error: err => {
            this.loadingService.complete();
            this.messageService.error(popResponseError(err));
          },
        });
      } else {

      }
    });
  }

  //Dùng để override param search theo nghiệp vụ nếu có
  reload() {
    this.search(true);
  }

  exportExcel() {
    if (this.dataTable.content.length !== 0) {
      let params = removeParamNullOrUndefined(this.prevParams);
      if (this.loadingService.loading) {
        return;
      }
      this.loadingService.start();
      this.serviceBase.exportExcel(this.fileNameExcel, params).subscribe({
        next: () => {
          this.loadingService.complete();
        },
        error: err => {
          this.loadingService.complete();
          this.messageService.error(popResponseError(err));
        },
      });
    } else {
      return;
    }
  }

  savePersonalized() {
    this.isPersonalized = !!_.find(this.cols, e => !e.visible);
    this.loadingService.start();
    const personalizedData = this.cols.map(e => {
      return {
        fieldName: e.fieldName,
        headerName: e.headerName,
        visible: e.visible,
        type: e.type,
        width: e.width,
      };
    });
    const body = {
      userProfileId: _.get(this.currUser, 'userInfo.id'),
      screenCode: this.screenCode,
      screenName: this.screenName,
      description: JSON.stringify(personalizedData),
    };
    this.personalizedApi.update(body).subscribe({
      next: value => {
        this.messageService.success('DIALOG.MESSAGE.SAVE-SUCCESS');
        this.accountService.getCurrentAccount().subscribe({
          next: (userProfile: any) => {
            this.sessionService.setSessionData(SessionKey.UserProfile, userProfile);
            this.loadingService.complete();
            this.currUser = this.sessionService?.getSessionData(SessionKey.UserProfile);
            this.initColData();
          },
        });
      },
      error: err => {
        this.messageService.error(popResponseError(err));
        this.loadingService.complete();
      },
    });
  }

  initColData() {
    const personalizedData = this.getPersonalized(this.screenCode);
    this.cols = _.cloneDeep(personalizedData);
    this.isPersonalized = !!_.find(this.cols, e => !e.visible);
  }

  // getValue(obj: any, path: string) {
  //   return lodash.get(obj, path);
  // }
  //
  // onDestroy() {}
  //
  // ngOnDestroy() {
  //   this.subscription?.unsubscribe();
  //   this.subscriptions?.forEach(sub => {
  //     sub.unsubscribe();
  //   });
  //   this.onDestroy();
  // }
  onBrowse(code: any, elementType: string = 'COMMON.BROWSE') {
    this.messageService.browseConfirm(elementType).subscribe(isConfirm => {
      if (isConfirm) {
        this.loadingService.start();
      } else {

      }
    });
  }

  onRefuse(code: any, elementType: string = 'COMMON.REFUSE') {
    this.messageService.refuseConfirm(elementType).subscribe(isConfirm => {
      if (isConfirm) {
        this.loadingService.start();
      } else {

      }
    });
  }
}
