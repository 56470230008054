<span *ngIf="readonly" class="readonly-view">
  <div class="readonly-label">{{ label | translate }}</div>
  <div class="readonly-content">{{ control.value || '---' }}</div>
</span>
<span *ngIf="!readonly" class="p-float-label">
  <p-inputMask
    (click)="op.show($event); onClick($event)"
    (keyup)="onKeyUp($event)"
    (onBlur)="onTouched(); onBlur()"
    (onInput)="onInput($event)"
    (onKeydown)="onKeyDown($event)"
    *ngIf="!readonly"
    [autoClear]="false"
    [formControl]="control"
    [ngClass]="{ 'ng-invalid ng-dirty': errors, readonly: readonly }"
    [placeholder]="placeholder | translate"
    [readonly]="readonly"
    [unmask]="true"
    mask="{{ '99' + separator + '99' }}"
    slotChar="-"></p-inputMask>

  <label class="block">
    <span *ngIf="showLabel"> {{ label | translate }}</span>
    <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
  >
  <p-overlayPanel #op>
    <ng-template pTemplate>
      <div class="time-picker-container">
        <div class="grid h-full">
          <div class="col-6 h-full overflow-scroll">
            <div
              (click)="setHour(hourItem)"
              *ngFor="let hourItem of hours"
              [ngClass]="{ selected: hourItem === hour }"
              class="item">
              {{ hourItem }}
            </div>
          </div>
          <div class="col-6 h-full overflow-scroll">
            <div
              (click)="setMinute(minuteItem)"
              *ngFor="let minuteItem of minutes"
              [ngClass]="{ selected: minuteItem === minute }"
              class="item">
              {{ minuteItem }}
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
  <div class="relative b-errors">
    <ng-container *ngIf="errors">
      <small class="p-error error-msg block fadeinup animation-duration-300">
        <span class="block">{{ getError().key | translate: getError().required }}</span>
      </small>
    </ng-container>
  </div>
</span>
