<div class="p-ng">
  <div [ngClass]="{ 'p-input-filled': !border, field: true }">
    <label class="block">
      <span *ngIf="showLabel"> {{ label | translate }}</span>
      <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span>
    </label>
    <p-editor
      (blur)="onTouched()"
      (onTextChange)="onTextChange()"
      [formControl]="control"
      [ngClass]="{ 'ng-invalid ng-dirty': errors }"
      [placeholder]="placeholder | translate"
      [readonly]="readonly"
    ></p-editor>
    <div class="relative b-errors">
      <ng-container *ngIf="errors">
        <small class="p-error error-msg block fadeinup animation-duration-300">
          <span class="block">{{ getError().key | translate: getError().required }}</span>
        </small>
      </ng-container>
    </div>
  </div>
</div>
