<div>
  <span class="flex">
    <p-checkbox
      (onChange)="doChange()"
      [binary]="true"
      [formControl]="control"
      [ngClass]="{ readonly: readonly }"
      [readonly]="readonly"
      label="{{ label | translate }}"></p-checkbox>
  </span>
  <div class="relative b-errors">
    <ng-container *ngIf="errors">
      <small class="p-error error-msg block fadeinup animation-duration-300">
        <span class="block">{{ getError().key | translate: getError().required }}</span>
      </small>
    </ng-container>
  </div>
</div>
