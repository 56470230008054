import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { REFRESH_BREACRUMB } from '@cores/utils/constants';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { StreamDataService } from 'src/app/core/services/stream-data.service';
import { buildBreadCrumb } from '@cores/utils/functions';
import { SeoService } from '@cores/services/seo.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppBreadcrumbComponent implements OnInit {
  items: MenuItem[] = [];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/' };

  constructor(
    private router: Router,
    private streamData: StreamDataService,
    private route: ActivatedRoute,
    private trans: TranslateService,
    private seo: SeoService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.items = buildBreadCrumb(this.router.routerState.root, trans);
        this.seo.set(this.items[this.items.length - 1].label || '');
      }
    });
    this.streamData.data$.subscribe(data => {
      if (data.key === REFRESH_BREACRUMB) {
        this.items = buildBreadCrumb(this.router.routerState.root, trans);
      }
    });
  }

  ngOnInit() {
    this.items = buildBreadCrumb(this.router.routerState.root, this.trans);
  }
}
