import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { StreamDataService } from './stream-data.service';
import { AccountService } from '@cores/services/account.service';
import { NotificationMessageService } from '@cores/services/message.service';
import { SessionKey } from '@cores/utils/enums';
import { popResponseError } from '@cores/utils/functions';

@Injectable({
  providedIn: 'root',
})
export class GuardService implements CanActivate {
  constructor(
    private streamData: StreamDataService,
    private sessionService: SessionService,
    private keycloakService: KeycloakService,
    private accountService: AccountService,
    private messageService: NotificationMessageService,
    private router: Router
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(_observable => {
      this.accountService.getCurrentAccount().subscribe({
        next: data => {
          this.sessionService.setSessionData(SessionKey.UserProfile, data);
          if (this.sessionService.getSessionData(SessionKey.UserProfile)?.userInfo?.lock === false) {
            if (_route.routeConfig?.path === 'not-allowed') {
              this.router.navigateByUrl('');
              return _observable.next(false);
            }
            return _observable.next(true);
          } else {
            if (_route.routeConfig?.path === 'not-allowed') {
              return _observable.next(true);
            }
            this.router.navigateByUrl('/not-allowed');
            return _observable.next(false);
          }
        },
        error: err => {

          this.messageService.error(popResponseError(err));
          if (_route.routeConfig?.path === 'not-allowed') {
            return _observable.next(true);
          }
          this.router.navigateByUrl('/not-allowed');
        },
      });
      //   .getInfoEmployee()
      // .pipe(catchError(() => of({})))
      //   .subscribe({
      //     next: (data) => {
      //       this.sessionService.setSessionData(SessionKey.UserProfile, data);
      //       this.streamData.passData(APP_LOADING, true);
      //   },
      // });
      // return _observable.next(true);
    });
  }
}
