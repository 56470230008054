import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  langChange: EventEmitter<number> = new EventEmitter();
  emitLanguageChangeEvent() {
    this.langChange.emit();
  }
  getLanguageChangeEmitter() {
    return this.langChange;
  }
}
