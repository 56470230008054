import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmpty } from '@cores/utils/functions';
import * as moment from 'moment';
import { REGEX_CONSTANT } from '@cores/utils/constants';

export function minDateToday(dateValue: any): ValidationErrors | null {
  return dateValue.isSameOrAfter(moment(), 'day') ? null : { minDateToday: {} };
}

export function maxDateToday(dateValue: any): ValidationErrors | null {
  return dateValue.isSameOrBefore(moment(), 'day') ? null : { maxDateToday: {} };
}

export function minDate(dateValue: any, minDate: any, minDateLabel: any): ValidationErrors | null {
  if (!moment(minDate).isValid()) return null;
  return dateValue.isSameOrAfter(minDate, 'day') ? null : { minDate: { minDateLabel: minDateLabel } };
}

export function maxDate(dateValue: any, maxDate: any, maxDateLabel: any): ValidationErrors | null {
  if (!moment(maxDate).isValid()) return null;
  return dateValue.isSameOrBefore(maxDate, 'day') ? null : { maxDate: { maxDateLabel: maxDateLabel } };
}

export function minAtTheMoment(date: any, time: string): ValidationErrors | null {
  if (moment(date).isAfter(moment())) {
    return null;
  } else {
    if (moment(time, 'HH:mm').isSameOrBefore(moment())) {
      return { minAtTheMoment: {} };
    } else {
      return null;
    }
  }
}

export function onlyAlphabetAndNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isEmpty(control.value)) {
      if (control.value.match(REGEX_CONSTANT.alphabetAndNumeric)) {
        return null;
      } else {
        return { pattern: {} };
      }
    } else {
      return null;
    }
  };
}

export function blockSpecialCharacter(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isEmpty(control.value)) {
      if (control.value.match(REGEX_CONSTANT.blockSpecialCharacter)) {
        return null;
      } else {
        return { pattern: {} };
      }
    } else {
      return null;
    }
  };
}

export function onlyNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isEmpty(control.value)) {
      let tmp = control.value;
      if (typeof control.value === 'number') {
        tmp = control.value.toString();
      }
      if (tmp.match(REGEX_CONSTANT.onlyNumber)) {
        return null;
      } else {
        return { pattern: {} };
      }
    } else {
      return null;
    }
  };
}

export function requiredLength(length: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (typeof control.value === 'string') {
      if (control.value.length !== length) {
        return {
          requiredLength: {
            requiredLength: length,
          },
        };
      } else {
        return null;
      }
    }
    return null;
  };
}

export function paperNumberLength(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isEmpty(control.value)) {
      let tmp = control.value;
      if (tmp.length === 8 || tmp.length === 9 || tmp.length === 12 || tmp.length === 16) {
        return null;
      } else {
        return { pattern: {} };
      }
    } else {
      return null;
    }
  };
}
