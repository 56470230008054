export const environment = {
  production: true,
  keycloak: {
    issuer: 'https://iam.lptech.vn/',
    realm: 'lpt',
    client: 'frontend',
  },
  client_code: 'LPTECH',
  endpoint_url: 'https://hcm-api.lptech.vn/v1',
  apmServerUrl: 'https://dev-apm.lptech.vn',
  firebaseConfig: {
    apiKey: 'AIzaSyBoeJ6amN_j_Y7lR1728vKcDfQuYwwJR6M',
    authDomain: 'lp-hcm.firebaseapp.com',
    projectId: 'lp-hcm',
    storageBucket: 'lp-hcm.appspot.com',
    messagingSenderId: '635245144247',
    appId: '1:635245144247:web:e864237d49f6f4aa1d8532',
  },
};
//Update
