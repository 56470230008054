import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage, isEmpty, isNotEmpty } from '@cores/utils/functions';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'lp-input-select',
  templateUrl: './lp-input-select.component.html',
  styleUrls: ['./lp-input-select.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputSelectComponent),
      multi: true,
    },
  ],
})
export class LPInputSelectComponent implements Validator, ControlValueAccessor, AfterViewInit {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() showLabel: boolean = true;
  @Input() options: any;
  @Input() isMultiSelect: boolean = false;
  @Input() display = 'comma';
  @Input() filter: boolean = false;
  @Input() optionValue: string = 'code';
  @Input() optionLabel: string = 'name';
  @Input() dropdownIcon: string = 'pi pi-chevron-down';
  @Input() optionDisabled: string = 'disabled';
  @Input() scrollHeight: string = '200px';
  @Input() showClear: boolean = true;
  @Input() readonly: boolean = false;
  @Input() tooltip?: any;
  @Input() border: boolean = false;
  @Input() virtualScroll: boolean = false;

  @Output() doChange = new EventEmitter<any>();
  initComplete: boolean = false;

  absControl!: AbstractControl;
  control = new UntypedFormControl();

  constructor(private el: ElementRef<HTMLElement>, private translate: TranslateService) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        if (this.initComplete) {
          this.onChange(value);
        } else {
          if (isNotEmpty(value)) {
            this.onChange(value);
            this.initComplete = true;
          }
        }
      }
    });
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  getOptionsLabel(value: string | string[]) {
    if (Array.isArray(value)) {
      return _.reduce(
        value,
        (prev: string[], curr: string) => {
          let tmpItem = _.find(this.options, e => {
            return e[this.optionValue] === curr;
          });
          return [...prev, tmpItem[this.optionLabel]];
        },
        []
      );
    }
    let findResult = _.find(this.options, e => e[this.optionValue] === value);
    if (findResult) {
      return findResult[this.optionLabel];
    }
    return '';
  }

  ngAfterViewInit() {
    console.debug('init view');
  }

  onChange = (value: string) => {};

  onTouched = () => {};

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  emitChange(event: any) {
    this.doChange.emit(event);
  }

  writeValue(value: any): void {
    value = isEmpty(value) ? null : value;
    if (value !== this.control.value) {
      this.control.setValue(value, { emitEvent: false });
      this.initComplete = true;
      if (this.absControl) {
        this.absControl.markAsPristine();
      }
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    return null;
  }
}
