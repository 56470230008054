import { Component, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage, removeAscent } from '@cores/utils/functions';
import { TranslateService } from '@ngx-translate/core';
import { isNull } from 'lodash';

@Component({
  selector: 'lp-input-text',
  templateUrl: './lp-input-text.component.html',
  styleUrls: ['./lp-input-text.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputTextComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputTextComponent),
      multi: true,
    },
  ],
})
export class LPInputTextComponent implements Validator, ControlValueAccessor {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() showLabel: boolean = true;
  @Input() pattern: string = '';
  @Input() searchIcon = false;
  @Input() needRemoveAscent = false;
  @Output() clickSearchEvent = new EventEmitter();
  @Input() required?: boolean | string;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() border: boolean = true;
  hasChanged: boolean = false;
  value: any;
  initialValue: any;
  absControl!: AbstractControl;
  control = new UntypedFormControl(null);

  constructor(private el: ElementRef<HTMLElement>, private translate: TranslateService) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        if (this.hasChanged) {
          //Check xem đây có phải lần onChange đầu tiên không, nếu là lần đầu thì cần check empty để tránh validate value khởi tạo
          this.onChange(value);
        } else {
          if (value === this.initialValue || isNull(value)) {
            return;
          }
          this.hasChanged = true;
          this.onChange(value);
        }
      }
    });
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  onModelChange() {
    this.onChange(this.value);
  }

  trimData() {
    if (this.needRemoveAscent) {
      this.control.setValue(removeAscent(this.control.value).toUpperCase());
    }
    this.control?.setValue(this.control?.value?.trim() || null);
  }

  checkControlValue() {
    return Array.isArray(this.control.value);
  }

  clickSearch() {
    this.clickSearchEvent.emit();
  }

  onChange = (value: string) => {};

  onTouched = () => {};

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  writeValue(value: string): void {
    this.initialValue = value;

    this.control.setValue(value);

    if (this.absControl) {
      this.absControl.markAsPristine();
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    return null;
  }
}
