import { AfterViewInit, Component, ElementRef, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage } from '@cores/utils/functions';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'lp-input-autocomplete',
  templateUrl: './lp-input-autocomplete.component.html',
  styleUrls: ['./lp-input-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputAutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputAutocompleteComponent),
      multi: true,
    },
  ],
})
export class LPInputAutocompleteComponent implements Validator, ControlValueAccessor, AfterViewInit {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() showLabel: boolean = true;
  @Input() options: any;
  @Input() isMultiSelect: boolean = true;
  @Input() display = 'comma';
  @Input() filter: boolean = false;
  @Input() field: string = 'name';
  @Input() optionDisabled: string = 'disabled';
  @Input() scrollHeight: string = '200px';
  @Input() showClear: boolean = true;
  @Input() readonly: boolean = false;
  @Input() tooltip?: any;
  @Input() border: boolean = false;
  @Input() virtualScroll: boolean = false;
  initComplete: boolean = false;

  filteredOptions: any[] = [];
  absControl!: AbstractControl;
  control = new UntypedFormControl();

  constructor(private el: ElementRef<HTMLElement>, private translate: TranslateService) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        const val = _.map(value, e => {
          return e.code;
        });
        this.onChange(val);
      }
    });
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  completeMethod(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    for (let option of this.options) {
      if (option.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(option);
      }
    }
    this.filteredOptions = filtered;
  }

  ngAfterViewInit() {

  }

  onChange = (value: any) => {};

  onTouched = () => {};

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  writeValue(value: any): void {
    let tmp = this.mapData(value);
    this.control.setValue(tmp);
  }

  mapData(valArray: string[]) {
    let result = _.reduce(
      valArray,
      (prev: string[], curr: string) => {
        let tmpItem = _.find(this.options, e => {
          return e.code === curr;
        });
        return [...prev, tmpItem];
      },
      []
    );
    return result;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    return null;
  }
}
