import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { KeycloakService } from 'keycloak-angular';
import { BaseService } from '@cores/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  userName!: string;

  constructor(http: HttpClient, private keycloakService: KeycloakService) {
    super(http, `${environment.endpoint_url}/adm/users/me`);
    this.userName = keycloakService.getUsername();
  }

  getCurrentAccount() {
    return this.http.get(`${this.baseURL}`);
  }
}
