import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lp-input-files',
  templateUrl: './lp-input-files.component.html',
  styleUrls: ['./lp-input-files.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputFilesComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputFilesComponent),
      multi: true,
    },
  ],
})
export class LPInputFilesComponent {
  @Input() label: string = 'EMPTY';
  @Input() options: any;
  @Input() readonly: boolean = false;
  @Input() tooltip?: any;

  constructor(private el: ElementRef<HTMLElement>, private translate: TranslateService) {}

  alert() {
    window.alert('Tính năng này tạm thời chưa hoạt động bạn nhé');
  }
}
