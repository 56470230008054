import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { StreamDataService } from './core/services/stream-data.service';
import { APP_LOADING } from './core/utils/constants';
import { SeoService } from '@cores/services/seo.service';

@Component({
  selector: 'app-root',
  template: `
    <app-loading *ngIf="!loaded"></app-loading>
    <router-outlet *ngIf="loaded"></router-outlet>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  loaded = false;
  subscription: any;
  constructor(
    private primeNGConfig: PrimeNGConfig,
    private streamData: StreamDataService,
    private translate: TranslateService,
    private seo: SeoService
  ) {
    this.streamData.data$.subscribe(data => {
      if (data.key === APP_LOADING) {
        this.loaded = true;
      }
    });
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('vi');
    this.seo.init('LPTech');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang?.match(/vi|en/) ? browserLang : 'vi');
    this.translate.get('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
    this.subscription = this.translate.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });
  }
  ngOnInit() {
    this.primeNGConfig.ripple = true;
    this.loaded = true;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
