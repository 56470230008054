import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { BaseService } from '@cores/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  constructor(http: HttpClient) {
    super(http, `${environment.endpoint_url}/notification`);
  }

  getBirthdayNoti() {
    return this.http.get(`${this.baseURL}/birthday`);
  }
}
