import { Router } from '@angular/router';
import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, takeUntil, timeout } from 'rxjs';
import { HttpCancelService } from '@cores/services/http-cancel.service';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private httpCancelService: HttpCancelService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    // req = req.url.startsWith(environment.keycloak.issuer)
    //   ? req.clone()
    //   : req.clone({
    //       setHeaders: {
    //         clientCode: environment.client_code,
    //       },
    //     });
    return next
      .handle(req)
      .pipe(timeout(timeoutValueNumeric), takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }
}
