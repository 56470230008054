<span *ngIf="!readonly" class="upload-file-container">
  <div class="upload-file-label">{{ label | translate }}</div>
  <div class="upload-file-content">
    <span class="upload-btn-container">
      <em class="lp-upload-cloud"></em>
      <span>{{ 'BUTTON.CHOOSE-FILE' | translate }}</span>
    </span>
  </div>
</span>
<span *ngIf="readonly" class="readonly-view">
  <div class="readonly-label">{{ label | translate }}</div>
  <div (click)="alert()" class="readonly-content">bangcap.jpg</div>
</span>
