import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'table-action',
  templateUrl: './table-action-panel.component.html',
  styleUrls: ['./table-action-panel.component.scss'],
})
export class TableActionPanelComponent {
  @Input() detail: boolean = false;
  @Input() view: boolean = false;
  @Input() edit: boolean = false;
  @Input() actionAssign: boolean = false;
  @Input() userAssign: boolean = false;
  @Input() roleAssign: boolean = false;
  @Input() lock: boolean = false;
  @Input() unlock: boolean = false;
  @Input() delete: boolean = true;
  @Input() browse: boolean = false;
  @Input() refuse: boolean = false;
  @Input() onboarding: boolean = false;
  @Input() email: boolean = false;
  @Input() download: boolean = false;

  @Output() detailClick: EventEmitter<any> = new EventEmitter();
  @Output() viewClick: EventEmitter<any> = new EventEmitter();
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();
  @Output() actionAssignClick: EventEmitter<any> = new EventEmitter();
  @Output() userAssignClick: EventEmitter<any> = new EventEmitter();
  @Output() roleAssignClick: EventEmitter<any> = new EventEmitter();
  @Output() lockClick: EventEmitter<any> = new EventEmitter();
  @Output() unlockClick: EventEmitter<any> = new EventEmitter();
  @Output() browseClick: EventEmitter<any> = new EventEmitter();
  @Output() refuseClick: EventEmitter<any> = new EventEmitter();
  @Output() onboardingClick: EventEmitter<any> = new EventEmitter();
  @Output() emailClick: EventEmitter<any> = new EventEmitter();
  @Output() downloadClick: EventEmitter<any> = new EventEmitter();

  onEmailClick() {
    this.emailClick.emit();
  }
  onBoardingClick() {
    this.onboardingClick.emit();
  }
  onRefuseClick() {
    this.refuseClick.emit();
  }
  onBrowseClick() {
    this.browseClick.emit();
  }
  onDetailClick() {
    this.detailClick.emit();
  }

  onViewClick() {
    this.viewClick.emit();
  }

  onEditClick() {
    this.editClick.emit();
  }

  onDeleteClick() {
    this.deleteClick.emit();
  }

  onActionAssignClick() {
    this.actionAssignClick.emit();
  }

  onUserAssignClick() {
    this.userAssignClick.emit();
  }

  onRoleAssignClick() {
    this.roleAssignClick.emit();
  }

  onLockClick() {
    this.lockClick.emit();
  }

  onUnlockClick() {
    this.unlockClick.emit();
  }

  onDownloadClick() {
    this.downloadClick.emit();
  }
}
