<div class="topbar">
  <div class="topbar-left">
    <a routerLink="">
      <img alt="" src="assets/images/topbar-logo.png"/>
    </a>
  </div>
  <div class="topbar-right">
    <!--BIRTHDAY NOTIFICATIONS-->
    <em (click)="birthdayPopup.toggle($event)" class="lp-birthday-cake-nontext birthday-icon">
      <p-badge
        *ngIf="birthDayNotifyCount > 0"
        [value]="birthDayNotifyCount > 99 ? '99+' : birthDayNotifyCount.toString()"
        class="birthday-count"></p-badge>
      <p-overlayPanel
        #birthdayPopup
        (onHide)="onBirthdayPopupHide()"
        [style]="{ width: '400px', overflow: 'hidden', top: '55px !important' }"
        styleClass="birthday-popup">
        <ng-template pTemplate>
          <div class="notify-header">{{ 'COMMON.BIRTHDAY' | translate }}</div>
          <div class="notify-content">
            <div class="notify-section">
              <p-panel
                (onAfterToggle)="onAfterToggle($event)"
                [collapsed]="true"
                [toggleable]="true"
                collapseIcon="pi pi-fw pi-angle-up"
                expandIcon="pi pi-fw pi-angle-down"
                header="{{ 'COMMON.TODAY' | translate }}"
                toggler="header">
                <div *ngIf="canCollapsed" class="notify-section_content">
                  <ul>
                    <li *ngFor="let item of birthDayData.today" class="birthday-item">
                      <div class="gender-icon">
                        <em *ngIf="item.genderName === 'Nam'" class="lp-gender-male"></em>
                        <em *ngIf="item.genderName === 'Nữ'" class="lp-gender-female"></em>
                        <em *ngIf="item.genderName === 'Khác'" class="lp-gender-other"></em>
                      </div>
                      <div class="info">
                        <span class="birthday-name">{{ item.code }} - {{ item.lastName + ' ' + item.firstName }}</span>
                        <div class="info-section">
                          <div class="info-item">
                            <em class="lp-briefcase"></em>
                            <span> {{ item.departmentName }}</span>
                          </div>
                          <div class="info-item">
                            <em class="lp-mini-bdcake"></em>
                            <span>
                              {{ item.birthDate | date: 'dd/MM/yyyy' }} -
                              {{ getAgeFromBirthday(item.birthDate) }} tuổi</span
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </p-panel>
            </div>

            <div class="notify-section">
              <p-panel
                (onAfterToggle)="onAfterToggle($event)"
                [collapsed]="true"
                [toggleable]="true"
                collapseIcon="pi pi-fw pi-angle-up"
                expandIcon="pi pi-fw pi-angle-down"
                header="{{ 'COMMON.THIS-WEEK' | translate }}"
                toggler="header">
                <div *ngIf="canCollapsed" class="notify-section_content">
                  <ul>
                    <li *ngFor="let item of birthDayData.thisWeek" class="birthday-item">
                      <div class="gender-icon">
                        <em *ngIf="item.genderName === 'Nam'" class="lp-gender-male"></em>
                        <em *ngIf="item.genderName === 'Nữ'" class="lp-gender-female"></em>
                        <em *ngIf="item.genderName === 'Khác'" class="lp-gender-other"></em>
                      </div>
                      <div class="info">
                        <span class="birthday-name">{{ item.code }} - {{ item.lastName + ' ' + item.firstName }}</span>
                        <div class="info-section">
                          <div class="info-item">
                            <em class="lp-briefcase"></em>
                            <span> {{ item.departmentName }}</span>
                          </div>
                          <div class="info-item">
                            <em class="lp-mini-bdcake"></em>
                            <span>
                              {{ item.birthDate | date: 'dd/MM/yyyy' }} -
                              {{ getAgeFromBirthday(item.birthDate) }} tuổi</span
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </p-panel>
            </div>
            <div class="notify-section">
              <p-panel
                (onAfterToggle)="onAfterToggle($event)"
                [collapsed]="true"
                [toggleable]="true"
                collapseIcon="pi pi-fw pi-angle-up"
                expandIcon="pi pi-fw pi-angle-down"
                header="{{ 'COMMON.THIS-MONTH' | translate }}"
                toggler="header">
                <div *ngIf="canCollapsed" class="notify-section_content">
                  <ul>
                    <li *ngFor="let item of birthDayData.thisMonth" class="birthday-item">
                      <div class="gender-icon">
                        <em *ngIf="item.genderName === 'Nam'" class="lp-gender-male"></em>
                        <em *ngIf="item.genderName === 'Nữ'" class="lp-gender-female"></em>
                        <em *ngIf="item.genderName === 'Khác'" class="lp-gender-other"></em>
                      </div>
                      <div class="info">
                        <span class="birthday-name">{{ item.code }} - {{ item.lastName + ' ' + item.firstName }}</span>
                        <div class="info-section">
                          <div class="info-item">
                            <em class="lp-briefcase"></em>
                            <span> {{ item.departmentName }}</span>
                          </div>
                          <div class="info-item">
                            <em class="lp-mini-bdcake"></em>
                            <span>
                              {{ item.birthDate | date: 'dd/MM/yyyy' }} -
                              {{ getAgeFromBirthday(item.birthDate) }} tuổi</span
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </p-panel>
            </div>
          </div>
        </ng-template>
      </p-overlayPanel>
    </em>

    <!--NOTIFICATIONS-->
    <em class="lp-bell ml-4"> </em>

    <!--SWITCH LANGUAGE-->
    <a (click)="$event.preventDefault(); switchLanguage()" class="ml-4" href="javascript:void(0)">
      <img
        [src]="'../../../../assets/images/icon-' + translate.currentLang + '.png'"
        alt="switch languages"
        class="block m-auto"
        width="20px"/>
    </a>

    <!--USER ACTION-->
    <a (click)="$event.preventDefault()" class="ml-4" href="#">
      <span (click)="op.toggle($event); getUserData()" class="profile-info-wrapper"
      ><div class="user-icon"></div>
      </span>
      <p-overlayPanel #op [style]="{ width: '300px' }">
        <ng-template pTemplate>
          <div class="account-body">
            <span class="account-name">{{ fullName || 'User' }} - {{ employeeCode || 'LPxxxx' }} </span>
            <span class="account-username">@{{ userName }}</span>
            <a class="account-text">Settings</a>
            <a class="account-text pb-0">Help</a>
          </div>
          <div (click)="logout()" class="logout pointer">
            <a href="javascript:void(0)">{{ 'BUTTON.LOGOUT' | translate }}</a>
          </div>
        </ng-template>
      </p-overlayPanel>
    </a>
  </div>
</div>
