<span *ngIf="readonly" class="readonly-view">
  <div class="readonly-label">{{ label | translate }}</div>
  <div class="readonly-content">
    {{ (control.value | number: '1.' + minFractionDigits + '-' + maxFractionDigits) || '---' }}
  </div>
</span>
<span *ngIf="!readonly" class="p-float-label">
  <p-inputNumber
    (onBlur)="onTouched()"
    (onInput)="onInput($event)"
    [formControl]="control"
    [maxFractionDigits]="maxFractionDigits"
    [minFractionDigits]="minFractionDigits"
    [ngClass]="{ 'ng-invalid ng-dirty': errors, readonly: readonly }"
    [placeholder]="placeholder | translate"
    [prefix]="prefix"
    [readonly]="readonly"
    [suffix]="suffix"
    [useGrouping]="useGrouping"
    locale="en-US"
  ></p-inputNumber>
  <label class="block">
    <span *ngIf="showLabel"> {{ label | translate }}</span>
    <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
  >
  <div class="relative b-errors">
    <ng-container *ngIf="errors">
      <small class="p-error error-msg block fadeinup animation-duration-300">
        <span class="block">{{ getError().key | translate: getError().required }}</span>
      </small>
    </ng-container>
  </div>
</span>
