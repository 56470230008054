import { map, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, Injector, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileModel } from 'src/app/core/models/user-profile.model';
import { SessionService } from 'src/app/core/services/session.service';
import { StreamDataService } from 'src/app/core/services/stream-data.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationMessageService } from 'src/app/core/services/message.service';
import { Action, FunctionCode, SessionKey } from 'src/app/core/utils/enums';
import { FunctionModel } from 'src/app/core/models/function.model';
import { LoadingService } from '@cores/services/loading.service';
import * as lodash from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@cores/services/account.service';

@Component({
  template: ` <ng-content></ng-content>`,
  providers: [DialogService],
})
export class BaseComponent implements OnDestroy {
  functionCode: string = '';
  subscription?: Subscription;
  subscriptions: Subscription[] = [];
  public loadingService!: LoadingService;
  public currUser: UserProfileModel;
  protected objFunction: FunctionModel | undefined;
  protected messageService!: NotificationMessageService;
  protected dialogService!: DialogService;
  protected router!: Router;
  protected route!: ActivatedRoute;
  protected location!: Location;
  protected streamDataService!: StreamDataService;
  protected sessionService!: SessionService;
  protected ref!: ChangeDetectorRef;
  protected fb!: UntypedFormBuilder;
  protected refDialog!: DynamicDialogRef;
  protected configDialog!: DynamicDialogConfig;
  protected translate!: TranslateService;
  protected accountService!: AccountService;

  constructor(public injector: Injector) {
    this.init();
    this.currUser = this.sessionService?.getSessionData(SessionKey.UserProfile);
    this.subscriptions.push(
      this.route.data.pipe(map(d => d['functionCode'])).subscribe((value: any) => {
        this.functionCode = value;
      })
    );
  }

  get Action() {
    return Action;
  }

  init() {
    this.messageService = this.injector.get(NotificationMessageService);
    this.dialogService = this.injector.get(DialogService);
    this.fb = this.injector.get(UntypedFormBuilder);
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
    this.location = this.injector.get(Location);
    this.streamDataService = this.injector.get(StreamDataService);
    this.sessionService = this.injector.get(SessionService);
    this.ref = this.injector.get(ChangeDetectorRef);
    this.loadingService = this.injector.get(LoadingService);
    this.refDialog = this.injector.get(DynamicDialogRef);
    this.configDialog = this.injector.get(DynamicDialogConfig);
    this.translate = this.injector.get(TranslateService);
    this.accountService = this.injector.get(AccountService);
  }

  getValue(obj: any, path: string) {
    return lodash.get(obj, path);
  }

  findDeep(userFunctions: any, functionCode: string, action: Action): boolean {
    for (let userFunction of userFunctions) {
      if (userFunction?.functionCode === functionCode) {
        return userFunction?.authorize?.includes(action);
      }
      if (userFunction?.functions) {
        if (this.findDeep(userFunction.functions, functionCode, action)) {
          return true;
        }
      }
    }
    return false;
  }

  checkRole(action: Action): boolean {
    const userFunctions = this.sessionService.getSessionData(SessionKey.UserProfile)['authorizationInfo'];
    return this.findDeep(userFunctions, this.functionCode, action);
  }

  checkFunctionTab(functionCode: FunctionCode): boolean {
    const userFunctions = this.sessionService.getSessionData(SessionKey.UserProfile)['authorizationInfo'];
    const functionToCheck = userFunctions.find((e: any) => e.functionCode === this.functionCode);
    return !!functionToCheck?.functions.find((e: any) => e.functionCode === functionCode);
  }

  onDestroy() {}

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscriptions?.forEach(sub => {
      sub.unsubscribe();
    });
    this.onDestroy();
  }
}
