import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  prefix: string = 'LPTech';
  constructor(private title: Title) {}

  init(prefix: string) {
    this.prefix = prefix;
    this.title.setTitle(`${this.prefix}`);
  }

  set(title: string) {
    this.title.setTitle(`${title} - ${this.prefix}`);
    return this;
  }
}
