<span *ngIf="readonly" class="readonly-view">
  <div class="readonly-label">{{ label | translate }}</div>
  <div
    *ngIf="options && control.value !== undefined; else empty"
    [value]="getOptionsLabel(control.value)"
    chipView
    class="readonly-content"></div>
  <ng-template #empty class="readonly-content">---</ng-template>
</span>
<span *ngIf="!readonly" class="p-float-label">
  <p-dropdown
  (onChange)="emitChange($event)"
    *ngIf="!isMultiSelect"
    [autoDisplayFirst]="false"
    [filter]="filter"
    [formControl]="control"
    [itemSize]="30"
    [ngClass]="{ 'ng-invalid ng-dirty': errors, readonly: readonly }"
    [optionDisabled]="optionDisabled"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [options]="options"
    [placeholder]="placeholder | translate"
    [readonly]="readonly"
    [resetFilterOnHide]="true"
    [scrollHeight]="scrollHeight"
    [showClear]="!readonly && showClear"
    [tooltip]="tooltip"
    [virtualScroll]="virtualScroll"
    appendTo="body">
  </p-dropdown>
  <p-multiSelect
    (onChange)="emitChange($event)"
    *ngIf="isMultiSelect"
    [display]="display"
    [dropdownIcon]="dropdownIcon"
    [filter]="filter"
    [formControl]="control"
    [label]="label"
    [ngClass]="{ 'ng-invalid ng-dirty': errors, readonly: readonly }"
    [optionDisabled]="optionDisabled"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [options]="options"
    [placeholder]="placeholder | translate"
    [readonly]="readonly"
    [scrollHeight]="scrollHeight"
    [tooltip]="tooltip"
    [virtualScroll]="virtualScroll"
    appendTo="body"></p-multiSelect>
  <label class="block">
    <span *ngIf="showLabel"> {{ label | translate }}</span>
    <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
  >

  <div class="relative b-errors">
    <ng-container *ngIf="errors">
      <small class="p-error error-msg block fadeinup animation-duration-300">
        <span class="block">{{ getError().key | translate: getError().required }}</span>
      </small>
    </ng-container>
  </div>
</span>
