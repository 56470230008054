import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { getNodeMenuByUrl, getUrlPathName } from '@cores/utils/functions';
import { MenuModel } from '@cores/models/menu.model';
import { HttpCancelService } from '@cores/services/http-cancel.service';
import { SessionService } from '@cores/services/session.service';
import { FunctionCode, SessionKey } from '@cores/utils/enums';
import { MenuIcon } from '@cores/utils/constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('toggleMenu', [
      transition(':enter', [style({ visibility: 'visible', height: 0 }), animate(`${150}ms ease-in`)]),
      transition(':leave', [animate(`${150}ms ease-out`, style({ visibility: 'hidden', height: 0 }))]),
    ]),
  ],
})
export class AppMenuComponent implements OnInit {
  @Output() staticMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() mouseEnter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() mouseLeave: EventEmitter<boolean> = new EventEmitter<boolean>();
  classMenu = 'menu left-menu-minimized';
  items: MenuModel[] = [];
  isLock = false;
  currentMenuItem: any;

  constructor(
    private router: Router,
    private httpCancelService: HttpCancelService,
    private sessionService: SessionService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentMenuItem = getNodeMenuByUrl({ children: this.items }, getUrlPathName(this.router.url));
        if (this.currentMenuItem) {
          this.currentMenuItem.active = true;
          this.setActiveMenu(this.items, this.currentMenuItem);
        }
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  ngOnInit() {
    this.items = this.getUserMenu();
    this.currentMenuItem = getNodeMenuByUrl({ children: this.items }, getUrlPathName(this.router.url));
    if (this.currentMenuItem) {
      this.currentMenuItem.active = true;
    }
    this.activeMenuItem(this.currentMenuItem);
    this.mouseEnterMenu();
    this.onStaticMenu();
  }

  getUserMenu() {
    const userMenu = this.sessionService.getSessionData(SessionKey.UserProfile)?.authorizationInfo;
    const finalMenu: MenuModel[] = userMenu.map((e: any, index: any) => {
      const tmpObj: MenuModel = {
        path: `[${index}]`,
        label: e?.functionName,
        isRootLevel: true,
        icon: MenuIcon[e?.functionCode],
        functionCode: e?.functionCode,
        orderNumber: e?.orderNumber,
        active: false,
      };
      if (e.url) {
        tmpObj.routerLink = e.url;
      }

      if (e.functionCode === FunctionCode.Dashboard) {
        tmpObj.routerLink = '/dashboard';
      } else {
        tmpObj.expanded = false;
        tmpObj.children = this.mapMenu(e.functions, tmpObj.path);
        tmpObj.children.sort((a, b) => {
          if (a.orderNumber && b.orderNumber) {
            return a.orderNumber - b.orderNumber;
          }
          return 0;
        });
      }
      return tmpObj;
    });
    finalMenu.sort((a, b) => {
      if (a.orderNumber && b.orderNumber) {
        return a.orderNumber - b.orderNumber;
      }
      return 0;
    });
    return finalMenu;
  }

  mapMenu(itemList: any, parentPath?: string): MenuModel[] {
    return itemList
      .filter((childItem: any) => childItem.url)
      .map((childItem: any, index: any) => {
        return {
          path: `${parentPath}.children[${index}]`,
          label: childItem?.functionName,
          routerLink: childItem?.url,
          functionCode: childItem?.functionCode,
          orderNumber: childItem?.orderNumber,
          active: false,
        };
      });
  }

  onStaticMenu() {
    this.isLock = !this.isLock;
    this.staticMenu.emit(this.isLock);
  }

  mouseEnterMenu() {
    this.classMenu = 'menu left-menu';
    this.mouseEnter.emit();
    _.forEach(this.items, element => {
      if (element.expanded !== undefined && !this.isLock && element.active) {
        element.expanded = true;
      }
    });
  }

  mouseLeaveMenu() {
    this.classMenu = this.isLock ? 'menu left-menu' : 'menu left-menu-minimized';
    this.mouseLeave.emit();
    _.forEach(this.items, element => {
      if (element.expanded && !this.isLock) {
        element.expanded = false;
      }
    });
  }

  activeMenuItem(item?: MenuModel) {
    if (item) {
      if (_.size(item.children) > 0) {
        item.active = !item.active;
      } else {
        item.active = true;
      }
      this.setActiveMenu(this.items, item);
    }
  }

  setActiveMenu(data: MenuModel[], itemSelect: MenuModel) {
    for (const item of data) {
      item.active =
        itemSelect.path?.startsWith(item.path) && itemSelect.path.length >= item.path.length && itemSelect.active;

      if (item.children) {
        item.expanded = item.active;
        this.setActiveMenu(item.children, itemSelect);
      }
    }
  }

  checkRole(_itemMenu: any) {
    // return (
    //   !itemMenu.role ||
    //   (itemMenu.role &&
    //     _.find(this.sessionService.getSessionData(SessionKey.UserProfile).rolesCode, role => role === itemMenu.role))
    // );
    return true;
  }
}
