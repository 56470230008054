import { Component, OnInit } from '@angular/core';
import { SessionService } from '@cores/services/session.service';
import { StreamDataService } from '@cores/services/stream-data.service';
import { REFRESH_BREACRUMB } from '@cores/utils/constants';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { LanguageService } from '@cores/services/language.service';
import { SessionKey } from '@cores/utils/enums';
import { UserProfileModel } from '@cores/models/user-profile.model';
import { NotificationService } from '@cores/services/notification.service';
import * as moment from 'moment';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class AppTopBarComponent implements OnInit {
  fullName?: string;
  userName?: string;
  employeeCode?: string;
  birthDayData: any;
  birthDayNotifyCount = 0;
  canCollapsed = false;

  constructor(
    private keycloakService: KeycloakService,
    private sessionService: SessionService,
    private langService: LanguageService,
    public translate: TranslateService,
    private streamData: StreamDataService,
    private notifyService: NotificationService
  ) {
    translate.use('vi');
  }

  ngOnInit() {
    this.getUserData();
    this.notifyService.getBirthdayNoti().subscribe({
      next: (value: any) => {
        this.birthDayData = value;
        this.birthDayNotifyCount = this.birthDayData.today.length;
      },
    });
  }

  switchLanguage() {
    this.translate.use(this.translate.currentLang === 'vi' ? 'en' : 'vi').subscribe({
      next: () => {
        localStorage.setItem(this.userName!, this.translate.currentLang);
        this.streamData.passData(REFRESH_BREACRUMB, true);
      },
    });
  }

  onBirthdayPopupHide() {
    this.canCollapsed = false;
  }

  onAfterToggle(event: any) {

    this.canCollapsed = true;
  }

  getAgeFromBirthday(birthDate: string) {
    return moment().year() - moment(birthDate).year();
  }

  getUserData() {
    const user: UserProfileModel = this.sessionService.getSessionData(SessionKey.UserProfile);
    this.fullName = user?.userInfo?.fullName;
    this.userName = user?.userInfo?.userName;
    this.employeeCode = user?.userInfo?.employeeCode;
  }

  logout() {
    this.keycloakService.logout(`${window.location.origin}/dashboard`).then();
  }
}
