<div class="p-dialog-header">
  <span class="p-dialog-title">{{ data.title }}</span>
  <div class="p-dialog-header-icons">
    <button (click)="cancel()" class="p-dialog-header-icon p-dialog-header-maximize p-link" type="button">
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
<div class="p-dialog-content">
  <span class="p-confirm-dialog-message"> {{ data.message }} {{ data.elementType }} ? </span>
  <div class="footer">
    <button
      (click)="confirm(true)"
      *ngIf="
        data.buttonTitle !== 'BUTTON.DELETE' &&
        data.buttonTitle !== 'BUTTON.CLOSE' &&
        data.buttonTitle !== 'BUTTON.CANCEL-PROGRESS'
      "
      [ngClass]="{
        'lp-dialog-save': data.buttonTitle === 'BUTTON.SAVE'
      }"
      icon="pi pi-check"
      label="{{ data.buttonTitle | translate }} "
      pButton></button>

    <button
      (click)="confirm(true)"
      *ngIf="data.buttonTitle === 'BUTTON.CLOSE'"
      class="p-button-outlined p-button-danger"
      label="{{ data.buttonTitle | translate }}"
      pButton
      type="button"></button>

    <button
      (click)="confirm(true)"
      *ngIf="data.buttonTitle === 'BUTTON.DELETE'"
      class="p-button-outlined p-button-danger"
      icon="pi pi-trash"
      label="{{ 'BUTTON.DELETE' | translate }}"
      pButton
      type="button"></button>

    <button
      (click)="confirm(true)"
      *ngIf="data.buttonTitle === 'BUTTON.CANCEL-PROGRESS'"
      class="p-button-outlined p-button-danger"
      icon="lp-cancel-progress"
      label="{{ 'BUTTON.CANCEL' | translate }}"
      pButton
      type="button"></button>

    <button
      (click)="confirm(false)"
      *ngIf="data.buttonTitle === 'BUTTON.CANCEL-PROGRESS'"
      class="lp-dialog-cancel"
      icon="pi pi-times"
      label="{{ 'BUTTON.CLOSE' | translate }}"
      pButton></button>

    <button
      (click)="confirm(false)"
      *ngIf="data.buttonTitle !== 'BUTTON.CANCEL-PROGRESS'"
      class="lp-dialog-cancel"
      icon="pi pi-times"
      label="{{ 'BUTTON.CANCEL' | translate }}"
      pButton></button>
  </div>
</div>
