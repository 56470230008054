<span *ngIf="readonly" class="readonly-view">
  <div class="readonly-label">{{ label | translate }}</div>
  <div *ngIf="checkControlValue()" [value]="control.value" chipView class="readonly-content"></div>
  <div *ngIf="!checkControlValue()" class="readonly-content">{{ control.value || '---' }}</div>
</span>
<span *ngIf="!readonly" [ngClass]="{ 'p-input-icon-right': searchIcon }" class="p-float-label">
  <em (click)="clickSearch()" *ngIf="searchIcon" class="lp-magnify-black"></em>
  <input
    (blur)="onTouched(); trimData();"
    (keyup.enter)="trimData()"
    *ngIf="!readonly"
    [formControl]="control"
    [ngClass]="{ 'ng-invalid ng-dirty': errors, readonly: readonly }"
    [placeholder]="placeholder | translate"
    [readOnly]="readonly"
    pInputText
    type="text" />
  <label>
    <span *ngIf="showLabel"> {{ label | translate }}</span>
    <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
  >
  <div class="relative b-errors">
    <ng-container *ngIf="errors">
      <small class="p-error error-msg block fadeinup animation-duration-300">
        <span class="block">{{ getError().key | translate: getError().required }}</span>
      </small>
    </ng-container>
  </div>
</span>
