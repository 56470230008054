import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DataTable } from '@cores/models/data-table.model';
import { PaginatorModel } from '@cores/models/paginator.model';
import { blockSpecialCharacter, onlyAlphabetAndNumber } from '@cores/utils/custom-validators';
import { CategoryCode, ColumnDataType } from '@cores/utils/enums';
import { popResponseError } from '@cores/utils/functions';
import { CommonCategoryService } from '@features/administration/data-config/services/common-category.service';
import { EmployeesManagementService } from '@features/employee/employee-list/services/employees-management.service';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { BaseActionComponent } from '../base-action.component';
import { TreeData } from '@shared/models/common-category.model';

@Component({
  selector: 'app-advanced-search-employee-single',
  templateUrl: './advanced-search-employee-single.component.html',
  styleUrls: ['./advanced-search-employee-single.component.scss'],
})
export class AdvancedSearchEmployeeSingleComponent extends BaseActionComponent implements OnInit {
  params = new UntypedFormGroup({});
  prevParams: any;
  paramsHasBeenSearched = true;
  dataTable: DataTable = {
    content: [],
    currentPage: 0,
    size: 5,
    totalElements: 0,
    totalPages: 0,
    first: 0,
    numberOfElements: 0,
  };
  dataDept: TreeData[] = []

  cols = [
    { fieldName: 'code', headerName: 'Mã nhân viên', width: '140px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'fullName', headerName: 'Họ tên', width: '200px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'birthDate', headerName: 'Ngày sinh', width: '140px', visible: true, type: 'date' },
    { fieldName: 'genderName', headerName: 'Giới tính', width: '120px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'departmentName', headerName: 'Phòng ban', width: '220px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'titleName', headerName: 'Chức danh', width: '220px', visible: true, type: ColumnDataType.Text },
    { fieldName: 'positionName', headerName: 'Vị trí', width: '220px', visible: true, type: ColumnDataType.Text },
  ];
  selectedEmployee = {};

  constructor(
    injector: Injector,
    private api: EmployeesManagementService,
    private commonCategoryApi: CommonCategoryService
  ) {
    super(injector, api);
    this.params = this.fb.group({
      employeeCode: [null],
      fullName: [null, blockSpecialCharacter()],
      titleCode: '',
      rankCode: '',
      positionCode: '',
      departmentCode:'',
      isActive: 1,
    });
  }

  select() {
    if (this.isLoading) {
      return;
    }
    this.refDialog.close(this.selectedEmployee);


  }

  pageChange(paginator: PaginatorModel) {
    this.dataTable.currentPage = paginator.page;
    this.dataTable.size = paginator.rows;
    this.dataTable.first = paginator.first;
    if (!this.paramsHasBeenSearched) {
      this.search(false);
      //Xoá params chưa được search khi chuyển trang
      this.params.reset();
      _.forOwn(this.prevParams, (value, key) => {
        this.params.get(key)?.setValue(value);
      });
    } else {
      this.search();
    }
    this.paramsHasBeenSearched = false;
  }

  search(firstPage?: boolean, searchWithPrevParams?: boolean) {
    //searchWithPrevParams dùng để bao trường hợp người dùng thay đổi params tìm kiếm, nhưng chưa ấn tìm kiếm. Vẫn có thể chuyển trang để xem data của bộ param cũ.
    if (firstPage) {
      this.dataTable.currentPage = 0;
      this.dataTable.first = 0;
    }
    this.isLoading = true;
    const paramsValue = searchWithPrevParams ? this.prevParams : _.get(this.params, 'value');
    const params = {
      page: this.dataTable.currentPage,
      size: this.dataTable.size,
      ...paramsValue,
    };
    this.api.search(params).subscribe({
      next: data => {
        this.dataTable = data;
        this.isLoading = false;
        this.prevParams = params;
        delete this.prevParams.size;
        delete this.prevParams.page;
        this.paramsHasBeenSearched = firstPage ? true : false; //firstPage chỉ bằng true khi ấn Tìm kiếm - dùng để phân biệt với action call API search khi chuyển trang
      },
      error: err => {
        this.messageService?.error(popResponseError(err));
        this.isLoading = false;
      },
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.params.valueChanges.subscribe(() => {
      this.paramsHasBeenSearched = false;
    });
    forkJoin([
      this.commonCategoryApi.getCategoryByCode(CategoryCode.TITLE),
      this.commonCategoryApi.getCategoryByCode(CategoryCode.RANK),
      this.commonCategoryApi.getCategoryByCode(CategoryCode.POSN),
      this.commonCategoryApi.getDepartmentCategory()
    ]).subscribe({
      next: ([title, rank, posn, department]) => {
        this.droplistData.title = title?.commons;
        this.droplistData.rank = rank?.commons;
        this.droplistData.position = posn?.commons;
        this.dataDept = department
      },
      error: err => {
        this.messageService.error(popResponseError(err));
        this.isLoading = false;
      },
    });
    this.search();

  }

  reload() {
    this.params.setValue({
      employeeCode: null,
      fullName: null,
      titleCode: null,
      rankCode: null,
      positionCode: null,
      departmentCode: null,
      isActive: 1,
    });
    this.paramsHasBeenSearched = true;
    this.search(true, false);
  }
}
