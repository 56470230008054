import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'stringJoin',
})
export class StringJoinPipe implements PipeTransform {
  transform(stringArray?: string[]): string {
    if (!stringArray || !Array.isArray(stringArray)) {
      return '';
    }
    return stringArray.join(', ');
  }
}
