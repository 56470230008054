import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[zoom]',
})
export class ZoomDirective {
  @Input() el: any;

  wheelData: number = 0;
  offsetTop: number = 0;
  offsetLeft: number = 0;
  private i: number = 1;
  private range: number = 0.2;

  constructor(private ref: ElementRef, private renderer: Renderer2) {}

  @HostListener('wheel', ['$event']) onMousewheel(event: any) {
    if (event.ctrlKey) {
      event.preventDefault();
      if (event.wheelDelta > 0 && this.i < 2.5) {
        this.renderer.setStyle(this.el, 'transition', 'all 200ms ease-in');
        this.renderer.setStyle(this.el, 'transform', `scale(${this.i + this.range})`);
        this.renderer.setStyle(this.el, 'transform-origin', `0 0`);
        this.i = this.i + this.range;
      }
      if (event.wheelDelta < 0 && this.i > 0.4) {
        this.renderer.setStyle(this.el, 'transition', 'all 200ms ease-out');
        this.renderer.setStyle(this.el, 'transform', `scale(${this.i - this.range})`);
        this.renderer.setStyle(this.el, 'transform-origin', `0 0`);
        this.i = this.i - this.range;
      }
    }
  }

  zoomIn() {
    if (this.i < 2.5) {
      this.renderer.setStyle(this.el, 'transition', 'all 200ms ease-in');
      this.renderer.setStyle(this.el, 'transform', `scale(${this.i + this.range})`);
      this.renderer.setStyle(this.el, 'transform-origin', `0 0`);
      this.i = this.i + this.range;
    }
  }

  zoomOut() {
    if (this.i > 0.4) {
      this.renderer.setStyle(this.el, 'transition', 'all 200ms ease-out');
      this.renderer.setStyle(this.el, 'transform', `scale(${this.i - this.range})`);
      this.renderer.setStyle(this.el, 'transform-origin', `0 0`);
      this.i = this.i - this.range;
    }
  }

  // @HostListener('mousedown', ['$event']) onClick(event: any) {
  //   this.offsetLeft = this.ref.nativeElement.offsetLeft;
  //   this.offsetTop = this.ref.nativeElement.offsetTop;
  //
  //   this.ref.nativeElement.style.top += this.offsetTop + 10 + 'px';
  // }
}
